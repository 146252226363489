.application {
  position: relative;
  padding-top: 131px;
  padding-bottom: 137px;
  border-radius: 10px;

  &__bg-image {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: right;
  }

  &__svg {
    position: relative;
    width: 100%;

    &-container {
      pointer-events: none;
      display: inline-block;
      position: absolute;
      right: 44px;
      top: 30px;
      width: 22px;
      height: 19px;
      opacity: 0.6;
      fill: #ffffff;
      transition: all 0.3s ease-in-out;

      &--active {
        transform: rotate(180deg);
      }
    }
  }

  &__title {
    &-send {
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100vh - 306px);
      flex-direction: column;
      &-description {
        max-width: 669px;
        margin: 0 auto;
        font-weight: 300;
        font-size: 34px;
        line-height: 33px;
        text-align: center;
      }

      &-text {
        margin-bottom: 53px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        font-weight: 700;
        font-size: 46px;
        line-height: 44px;
        color: #ffffff;
        padding-bottom: 20px;

        &::after {
          content: '';
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          bottom: 0;
          width: calc(100% - 10px);
          height: 2px;
          background-color: #feed00;
        }
      }
    }
    &-error {
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100vh - 306px);
      flex-direction: column;
      &-description {
        max-width: 669px;
        margin: 0 auto;
        font-weight: 300;
        font-size: 34px;
        line-height: 33px;
        text-align: center;
      }

      &-text {
        margin-bottom: 53px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        font-weight: 700;
        font-size: 46px;
        line-height: 44px;
        color: #ffffff;
        padding-bottom: 20px;

        &::after {
          content: '';
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          bottom: 0;
          width: calc(100% - 10px);
          height: 2px;
          background-color: #feed00;
        }
      }
    }
    &-case {
      margin-bottom: 53px;
      text-align: center;
    }
    &-text {
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      color: #ffffff;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 100px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  &__form {
    max-width: 628px;
    padding: 0px 34px;
    margin: 0 auto;
    margin-top: 53px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__error {
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 23px;
    line-height: 22px;
    color: #580505;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__input {
    &-error {
      background-color: rgba(122, 49, 49, 0.7);
    }
    margin-bottom: 14px;
    display: block;
    position: relative;
    padding: 21px 21px 21px 24px;
    width: 100%;
    opacity: 0.4;
    border: 2px solid #ffffff;
    border-radius: 10px;
    font-weight: 300;
    font-size: 23px;
    line-height: 22px;
    color: #ffffff;

    &-change {
      cursor: pointer;
      position: relative;
      text-align: left;
      font-weight: 400;
      padding: 23.5px 75px 23.5px 24px;
      &-item {
        cursor: pointer;
        font-weight: 400;
        font-size: 23px;
        line-height: 22px;
        padding: 21px 0px;
        color: #000;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        transition: all 0.3s ease-in-out;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #feed00;
            background-color: #9b9b9b;
            transition: all 0.3s ease-in-out;
          }
        }

      }
      &-list {
        display: none;
        // padding: 10px;
        overflow: hidden;
        height: 0;
        position: absolute;
        width: 100%;
        border-radius: 10px;
        top: 73px;
        z-index: 5;
        list-style: none;
        transition: all 0.3s ease-in-out;
        &--active {
          background-color: #feed00;
        }
      }
    }

    &::-webkit-input-placeholder {
      font-weight: 300;
      font-size: 23px;
      line-height: 22px;
      color: #ffffff;
      opacity: 0.6;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 27px;
    line-height: 26px;
    margin-bottom: 60px;
    color: #ffffff;
  }

  &__btn {
    cursor: pointer;
    margin-top: 55px;
    margin-bottom: 24px;
    text-transform: uppercase;
    width: 100%;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    color: #000000;
    padding: 30px 0px;
    background: #feed00;
    border-radius: 10px;
  }

  &__text {
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
    text-align: center;

    color: #ffffff;

    &-link {
      color: #ffffff;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 59px;
    background-image: none;
    background: #202020;
    border-radius: 10px;
    min-height: 613px;

    &__form {
      margin-top: 0px;
    }

    &__bg-image {
      display: none;
    }

    &__error {
      font-size: 3vw;
      line-height: 2.9vw;
      color: #ef2626;
    }

    &__input {
      font-size: 3vw;
      &-change {
        font-size: 3vw;
        &-item {
          font-size: 3vw;
        }
      }
      &::-webkit-input-placeholder {
        font-size: 3vw;
      }
    }

    &__description {
      text-align: left;
      font-size: 19px;
      line-height: 24px;
    }

    &__btn {
      margin-top: 46px;
      font-size: 21px;
      line-height: 20px;
      max-width: 360px;
      width: 100%;
      padding: 20px 0px;
    }

    &__text {
      font-size: 14px;
      line-height: 13px;
    }

    &__title {
      &-text {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 26px;
        line-height: 28px;
        text-align: left;
        &::after {
          display: none;
        }
      }
      &-case {
        padding-left: 34px;
        padding-right: 34px;
        margin-bottom: 0px;
      }
      &-send {
        justify-content: inherit;
        &-text {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 26px;
          line-height: 28px;
          text-align: left;
          &::after {
            display: none;
          }
        }

        &-description {
          text-align: left;
          font-size: 19px;
          line-height: 24px;
          color: #fffff9;
        }
      }
    }
  }

  @media screen and (max-width: 428px) {
    padding-top: 53px;

    &__error {
      font-size: 3.5vw;
      line-height: 3.27vw;
    }

    &__title {
      &-text {
        padding-bottom: 24px;
      }
    }

    &__svg-container {
      right: 25px;
      top: 17px;
    }

    &__form {
      padding: 0px;
      // padding-left: 34px;
      // padding-right: 27px;
    }

    &__description {
      margin-bottom: 29px;
    }

    &__input {
      padding: 12px 12px 12px 18px;
      margin-bottom: 9px;
      font-size: 3.91vw;

      &::-webkit-input-placeholder {
        font-size: 3.91vw;
      }

      &-change {
        padding: 12px 60px 12px 18px;
        &-item {
          padding: 15px 0px;
          font-size: 4vw;
        }

        &-list {
          top: 51px;
        }
      }
    }

    &__btn {
      margin-top: 35px;
      margin-bottom: 14px;
      padding: 16px 0px;
      font-size: 4.91vw;
      line-height: 4.67vw;
    }
    &__text {
      max-width: 310px;
    }
  }
}
