.timetable-calendar {
  padding-top: 97px;
  padding-bottom: 115px;
  background-color: #fff;
  color: #000000;

  &__popup {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20;

    &-bg {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 21;
    }

    &-container {
      padding-top: 100px;
      padding-bottom: 100px;
      position: fixed;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      z-index: 22;
      max-width: 1408px;
      width: calc(100% - 80px);
      background-color: #fff;

      &-close {
        cursor: pointer;
        position: absolute;
        right: 54px;
        top: 38px;
      }

      &-title {
        margin-bottom: 33px;
        font-weight: 400;
        font-size: 41px;
        line-height: 39px;
        text-align: center;
        color: #000000;
      }

      &-text {
        margin-bottom: 33px;
        font-weight: 500;
        font-size: 19px;
        line-height: 18px;
        text-align: center;
        color: #555555;
      }

      &-name {
        margin-bottom: 55px;
        font-weight: 300;
        font-size: 28px;
        line-height: 27px;
        text-align: center;
        color: #545454;
      }

      &-traning {
        &-list {
          margin-bottom: 70px;
        }
        &-pay {
          margin-bottom: 9px;
          font-weight: 400;
          font-size: 19px;
          line-height: 18px;
          text-align: center;
          color: #000000;
        }

        &-write {
          font-weight: 400;
          font-size: 19px;
          line-height: 18px;
          text-align: center;
          color: #000000;
        }
      }

      &-date {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 26px;
        line-height: 25px;
        text-align: center;
        color: #000000;
      }

      &-description {
        max-width: 1094px;
        margin: 0 auto;
        margin-bottom: 54px;
        font-weight: 300;
        font-size: 25px;
        line-height: 43px;
        text-align: center;
        color: #000000;
      }

      &-notation {
        font-weight: 500;
        font-size: 19px;
        line-height: 18px;
        text-align: center;
        color: #000000;
      }
    }
  }

  &__btn {
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 14px;
    text-transform: uppercase;
    max-width: 559px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    color: #000000;
    padding: 30px 0px;
    background-color: #feed00;
    border-radius: 10px;
  }

  &__description {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
  }

  &__hours {
    &-list {
      list-style: none;
    }
    &-item {
      cursor: pointer;
      padding-right: 10px;
      padding-bottom: 22px;
      padding-left: 21px;
      padding-top: 23px;
      height: 200px;

      &--active {
        background-color: #feed00;
      }

      &-body {
        pointer-events: none;
      }

      &:not(:last-child) {
        border-bottom: 2px solid #d7d7d7;
      }
      &-case {
        &-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          &-title {
            font-weight: 900;
            font-size: 1.3vw;
            line-height: 24px;
          }

          &-min {
            font-weight: 400;
            font-size: 1vw;
            line-height: 14px;
          }
        }
      }

      &-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 1.1vw;
        line-height: 24px;
        margin-bottom: 14px;
      }

      &-text {
        margin-bottom: 7px;
        font-weight: 400;
        font-size: 0.7vw;
        line-height: 11px;
        color: #555555;
      }

      &-description {
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 0.8vw;
        line-height: 14px;
        color: #545454;
      }
      &-svg {
        display: flex;
        &-pay {
          margin-right: 12px;
        }

        &-mobile {
          display: none;
        }
      }
    }
  }

  &-date {
    &__item {
      width: 178px;
      height: 3.3vw;
      font-weight: 500;
      font-size: 1.3vw;
      line-height: 26px;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      &-month {
        margin: 0px 3px;
      }
      &--active {
        background: #e8e8e8;
        border-radius: 10px;
      }

      &:nth-child(7n) {
        color: red;
      }
      &:nth-child(7n - 1) {
        color: red;
      }
    }
  }

  &__slider {
    &-date {
      max-width: 87vw;
      margin: 0 auto;
      margin-bottom: 88px;
      &-btn {
        position: relative;
      }
    }
  }

  .swiper-button-next {
    top: 1.4vw;
    &::after {
      font-size: 18px;
      color: #aeaeae;
    }
  }
  .swiper-button-prev {
    top: 1.4vw;
    &::after {
      color: #aeaeae;
      font-size: 18px;
    }
  }
  .swiper__timetable-calendar-date {
    display: flex;
    max-width: 78vw;
    margin: 0 auto;
  }

  .swiper__timetable-calendar {
    display: flex;
    max-width: 78vw;
    margin: 0 auto;
    margin-bottom: 116px;

    .swiper-slide {
      border-right: 2px solid #d7d7d7;

      &:nth-child(7n) {
        border-right: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__hours {
      &-item {
        padding-right: 4px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-top: 15px;
        height: 160px;

        &-svg {
          &-pay {
            svg {
              width: 15px;
            }
          }
          &-write {
            svg {
              width: 15px;
            }
          }
        }

        &-case {
          &-time {
            margin-bottom: 5px;
            &-title {
              font-size: 12px;
            }

            &-min {
              font-size: 8px;
            }
          }
        }

        &-title {
          font-size: 1.2vw;
          line-height: 13px;
          margin-bottom: 6px;
        }

        &-text {
          font-size: 7px;
        }

        &-description {
          margin-bottom: 7px;
          font-size: 7px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &__btn {
      max-width: 382px;
      font-size: 21px;
      line-height: 20px;
      padding: 15px 0px;
    }

    &__description {
      font-size: 14px;
      line-height: 13px;
    }

    &__popup {
      &-container {
        right: inherit;
        transform: inherit;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: 120px;

        &-close {
          right: 18px;
          top: 20px;
        }

        &-title {
          font-size: 34px;
          line-height: 33px;
          margin-bottom: 14px;
        }

        &-text {
          margin-bottom: 21px;
          font-size: 15px;
          line-height: 14px;
        }

        &-name {
          font-size: 18px;
          line-height: 17px;
          margin-bottom: 32px;
        }

        &-traning {
          &-list {
            margin-bottom: 32px;
          }
          &-pay {
            font-size: 14px;
            line-height: 13px;
          }

          &-write {
            font-size: 14px;
            line-height: 13px;
          }
        }

        &-date {
          font-size: 22px;
          line-height: 21px;
        }

        &-description {
          font-size: 19px;
          line-height: 18px;
          margin: 0 38px;
          margin-bottom: 25px;
        }

        &-notation {
          font-size: 19px;
          line-height: 18px;
          margin: 0 38px;
        }
      }
    }
    .swiper__timetable-calendar-date {
      max-width: 71vw;
      .swiper-slide {
        height: 62px;
      }
    }

    .swiper__timetable-calendar {
      max-width: 98vw;
    }

    .timetable-calendar__slider-date {
      max-width: 95vw;
    }

    .swiper-button-next {
      top: 25px;
    }
    .swiper-button-prev {
      top: 25px;
    }

    .swiper__timetable-calendar .swiper-slide {
      border-right: none;
    }

    &__hours {
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 92px;
        &-body {
          margin-right: 27px;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        &-title {
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          font-size: 20px;
          line-height: 19px;
        }

        &-text {
          font-size: 12px;
          line-height: 11px;
        }

        &-description {
          font-size: 15px;
          line-height: 14px;
        }

        &-svg {
          align-items: center;
          &-mobile {
            display: block;
            width: 50px;
            height: 50px;
          }

          &-pay {
            svg {
              width: 22px;
            }
          }
          &-write {
            svg {
              width: 19px;
            }
          }
        }
        &-case {
          display: flex;
          &-time {
            margin-right: 16px;
            flex-direction: column;
            justify-content: center;
            &-title {
              margin-bottom: 8px;
              font-size: 25px;
              line-height: 24px;
            }

            &-min {
              font-size: 15px;
              line-height: 14px;
            }
          }
        }
      }
    }

    &-date {
      &__item {
        flex-direction: column;
        &-month {
          display: none;
        }

        &--active {
          background-color: #feed00;
        }

        &-number {
          font-size: 30px;
          line-height: 29px;
          margin-bottom: 5px;
        }

        &-day {
          font-weight: 300;
          font-size: 19px;
          line-height: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    &-date {
      &__item {
        &-number {
          font-size: 7vw;
          margin-bottom: 0px;
        }

        &-day {
          font-size: 4vw;
        }
      }
    }
    &__hours {
      &-item {
        &-body {
          margin-right: 15px;
        }

        &-title {
          font-size: 2.9vw;
        }

        &-text {
          font-size: 2.8vw;
        }

        &-description {
          font-size: 2.9vw;
        }

        &-case {
          margin-right: 6px;
          &-time {
            margin-right: 16px;
            &-title {
              font-size: 7vw;
            }

            &-min {
              font-size: 4vw;
            }
          }
        }
      }
    }
  }
}
