.footer {
  padding: 63px 39px 63px 40px;

  &__mobile {
    display: none;

    &-title {
      &-case {
        margin-bottom: 59px;
        text-align: center;
      }
      &-text {
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        padding-bottom: 15px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 19px;
        color: #ffffff;

        &::after {
          content: '';
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          bottom: 0;
          width: calc(100% + 30px);
          height: 2px;
          background-color: #feed00;
        }
      }
    }

    &-social {
      &-list {
        list-style: none;
        margin-top: 49px;
        display: flex;
        justify-content: center;
      }

      &-item {
        &-inst {
          cursor: pointer;
          margin-right: 57px;
          fill: #000000;
        }
        &-vk {
          margin-right: 50px;
          cursor: pointer;
          fill: #000000;
        }

        &-tg {
          display: inline-block;
          cursor: pointer;
          fill: #000000;
          path {
            width: 54px;
            height: 49px;
          }
        }
      }
    }

    &-logo {
      display: block;
      margin: 0 auto;
    }

    &-stocks {
      margin-top: 30px;
      margin-bottom: 100px;
      padding: 30px 38px 48px;
      background-color: #feed00;
      border-radius: 10px;
      color: #2c2c2c;
      text-transform: uppercase;
      &-text {
        font-weight: 300;
        font-size: 23px;
        line-height: 33px;
        text-align: center;
      }

      &-title {
        font-weight: 500;
        font-size: 23px;
        line-height: 33px;
        text-align: center;
        color: #2c2c2c;
      }
    }
  }

  &__header {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    margin-bottom: 78px;

    &-mir {
      margin-left: 10px;
      margin-right: 10px;
    }

    &__sbp {
      margin-right: 10px;
    }

    &-card {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      padding: 0px 30px;
      justify-content: center;
    }

    &-logo {
      height: 100%;
    }

    &-list:nth-child(2) {
      margin-right: calc((1920px + 100%) / 36);
    }

    &-list {
      &-body {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-item {
      &-title {
        margin-bottom: 0.73vw;
        font-weight: 700;
        font-size: 0.9vw;
        line-height: 1.66vw;
        color: #feed00;
      }

      &-text {
        display: block;
        font-weight: 300;
        font-size: 0.8vw;
        line-height: 1.64vw;
        color: #ffffff;
      }

      &-inn {
        margin-bottom: 1.5vw;
        max-width: 9vw;
      }

      &-telephone {
        display: flex;
        align-items: center;
        svg {
          fill: white;
          margin-right: 5px;
          transition: all 0.3s ease-in-out;
        }
        transition: all 0.3s ease-in-out;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #feed00;
            svg {
              fill: #feed00;
            }
          }
        }
      }

      &-mail {
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
          fill: white;
          transition: all 0.3s ease-in-out;
        }
        transition: all 0.3s ease-in-out;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #feed00;
            svg {
              fill: #feed00;
            }
          }
        }
      }

      &-map {
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 15px;
        max-width: 360px;
        height: 189px;
        border-radius: 10px;
        overflow: hidden;
        background-color: antiquewhite;
      }

      &-btn {
        padding: 20px 0px;
        margin: 0 auto;
        margin-bottom: 145px;
        display: block;
        max-width: 360px;
        width: 100%;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 21px;
        line-height: 20px;
        color: #000000;
        background: #feed00;
        border-radius: 10px;

        &--up {
          margin-bottom: 66px;
        }
      }
    }

    &-btns {
      display: flex;
      flex-direction: column;
      max-width: 326px;
      width: 100%;
    }

    &-btn {
      text-transform: uppercase;
      max-width: 326px;
      width: 100%;
      height: 57px;
      padding-top: 20px;
      text-align: center;
      font-weight: 700;
      font-size: 17px;
      line-height: 16px;
      border-radius: 10px;

      &--yellow {
        margin-bottom: 20px;
        background: #feed00;
        color: #000000;
      }

      &--stroke {
        color: #feed00;
        border: 2px solid #feed00;
      }
    }
  }

  &__main {
    margin-bottom: 48px;
    padding-left: 10px;
    &-list {
      display: flex;
      justify-content: space-between;
      list-style: none;
    }

    &-item {
      cursor: pointer;
      font-weight: 700;
      font-size: 19px;
      line-height: 18px;
      color: #7b7b7b;
      opacity: 0.93;
      a {
        color: #CECECE;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;

    &-application {
      display: flex;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-social-container {
      border: 2px solid #fff;
      border-radius: 10px;
      padding: 7px;
    }

    &-developed {
      margin-bottom: 19px;
      font-weight: 700;
      font-size: 19px;
      line-height: 18px;
      color: #ffffff;
    }

    &-privilege {
      font-weight: 300;
      font-size: 15px;
      line-height: 14px;
      color: #ffffff;
    }

    &-number {
      font-weight: 700;
      font-size: 45px;
      line-height: 43px;
      color: #feed00;
    }

    &-social {
      &-list {
        list-style: none;
        display: flex;
      }

      &-item {
        &-inst {
          cursor: pointer;
          margin-right: 33px;
          fill: white;
          opacity: 0.6;
        }
        &-vk {
          cursor: pointer;
          margin-right: 22px;
          fill: white;
          opacity: 0.6;
        }

        &-tg {
          cursor: pointer;
          fill: white;
          opacity: 0.6;
        }
      }
    }

    &-app-store {
      color: #ffffff;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 30px;
      // padding-left: 50px;
      position: relative;
      // &::before {
      //   position: absolute;
      //   content: '';
      //   left: 0;
      //   top: 0;
      //   width: 40px;
      //   height: 40px;
      //   background-image: url('./assets/images/apple.svg');
      //   background-repeat: no-repeat;
      // }

      img {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }

      &-download {
        margin-bottom: 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }

      &-title {
        font-size: 22px;
      }
    }

    &-play-market {
      color: #ffffff;
      display: flex;
      align-items: center;
      cursor: pointer;
      // padding-left: 58px;
      position: relative;
      // &::before {
      //   position: absolute;
      //   content: '';
      //   left: 0;
      //   top: -10px;
      //   width: 60px;
      //   height: 60px;
      //   background-image: url('./assets/images/google-play.svg');
      //   background-repeat: no-repeat;
      // }

      img {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }

      &-download {
        margin-bottom: 6px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }

      &-title {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    &__header {
      margin-bottom: 50px;

      &-logo {
        width: 140px;
      }
      &-btns {
        width: 210px;
      }

      &-mir {
        width: 120px;
      }

      &-sbp {
        width: 120px;
      }

      &-btn {
        font-size: 15px;
        height: 45px;
        padding-top: 13px;
      }
      &-list:nth-child(2) {
        margin-right: 10px;
      }
    }

    &__main {
      &-item {
        font-size: 14px;
      }
    }

    &__bottom {
      &-number {
        font-size: 28px;
      }

      &-developed {
        margin-bottom: 10px;
        font-size: 14px;
      }

      &-privilege {
        font-size: 10px;
      }

      &-social {
        &-item {
          &-inst {
            margin-right: 10px;
            width: 30px;
          }
          &-vk {
            margin-right: 10px;
            width: 30px;
          }
          &-tg {
            margin-right: 0px;
            width: 30px;
          }
        }
      }

      &-app-store {
        margin-right: 10px;
        // padding-left: 35px;
        &::before {
          // top: -6px;
          transform: scale(0.5);
        }

        &-download {
          font-size: 10px;
          margin-bottom: 0;
        }

        &-title {
          font-size: 16px;
        }
      }
      &-play-market {
        // padding-left: 45px;
        &::before {
          transform: scale(0.5);
          // top: -15px;
        }

        &-download {
          font-size: 10px;
          margin-bottom: 0;
        }

        &-title {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__header {
      margin-bottom: 50px;

      &-logo {
        width: 90px;
      }
      &-btns {
        width: 130px;
      }
      &-btn {
        font-size: 10px;
        height: 40px;
      }

      &-list:nth-child(2) {
        margin-right: 10px;
      }

      &-sbp {
        width: 60px;
      }

      &-mir {
        width: 60px;
      }

      &-item {
        &-title {
          font-size: 1.3vw;
          margin-bottom: 15px;
        }

        &-text {
          font-size: 1.02vw;
        }
      }
    }

    &__main {
      &-item {
        font-size: 10px;
      }
    }

    &__bottom {
      &-number {
        font-size: 18px;
      }

      &-developed {
        margin-bottom: 5px;
        font-size: 9px;
      }

      &-privilege {
        font-size: 8px;
      }

      &-social {
        &-item {
          &-inst {
            width: 25px;
          }
          &-vk {
            width: 25px;
          }
          &-tg {
            width: 25px;
          }
        }
      }

      &-app-store {
        margin-right: 10px;
        // padding-left: 35px;
        &::before {
          transform: scale(0.5);
          // top: -6px;
        }

        &-download {
          font-size: 8px;
        }

        &-title {
          font-size: 14px;
        }
      }
      &-play-market {
        // padding-left: 42px;
        &::before {
          transform: scale(0.5);
          // top: -15px;
        }

        &-download {
          font-size: 8px;
        }

        &-title {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-top: 75px;
    padding-bottom: 45px;

    &__main {
      &-list {
        flex-direction: column;
        padding: 0px 26px;
        text-align: center;
      }

      &-item {
        font-size: 19px;
        line-height: 18px;

        &:not(:last-child) {
          margin-bottom: 22px;
        }

        &:last-child {
          margin-bottom: 57px;
        }
      }
    }

    &__mobile {
      display: block;
    }

    &__header {
      &-mir {
        margin-left: 0;
        margin-right: 10px;
      }
      &-list {
        padding: 0px 34px;
        text-align: center;
      }

      &-item {
        &-title {
          font-size: 22px;
        }

        &-text {
          font-size: 18px;
          line-height: 4.64vw;
        }
      }
    }

    &__desctop {
      display: none;
    }
    &__bottom {
      &-application {
        margin-top: 10px;
        justify-content: start;
        max-width: 368px;
        margin: 0 auto;
        margin-top: 20px;
      }

      &-developed {
        margin-bottom: 62px;
        font-size: 19px;
        line-height: 18px;
      }

      &-privilege {
        font-size: 15px;
        line-height: 14px;
      }

      &-app-store {
        margin-right: 15px;
        // padding-left: 45px;
        &::before {
          transform: scale(0.9);
          // top: 3px;
          // left: 5px;
        }

        &-download {
          margin-bottom: 5px;
          word-spacing: 3px;
          font-size: 12px;
        }

        &-title {
          font-size: 20px;
        }
      }
      &-play-market {
        // padding-left: 45px;
        &::before {
          transform: scale(0.9);
          // top: -5px;
        }

        &-download {
          margin-bottom: 2px;
          font-size: 12px;
        }

        &-title {
          font-size: 18px;
        }
      }

      &-info {
        padding: 0 28px;
        align-items: start;
      }

      &-social {
        &-list {
          padding: 0px 30px;
          margin-bottom: 44px;
          justify-content: center;
        }

        &-item {
          &:not(:last-child) {
            margin-right: 51px;
          }
          &-inst {
            transform: scale(1.2);
          }
          &-vk {
            transform: scale(1.2);
          }
          &-tg {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  @media screen and (max-width: 428px) {
    &__main {
      &-list {
        text-align: left;
      }
    }

    &__mobile {
      &-logo {
        padding: 0px 4vw;
      }

      &-title {
        &-case {
          margin-bottom: 53px;
        }
      }

      &-stocks {
        margin-top: 47px;
      }

      &-social {
        &-list {
          margin-top: 29px;
        }
      }
    }

    &__bottom {
      &-application {
        justify-content: center;
      }

      &-social {
        &-list {
          justify-content: left;
        }
      }
      &-app-store {
        &-download {
          font-size: 2.4vw;
        }

        &-title {
          font-size: 4.5vw;
        }
      }
      &-play-market {
        &-download {
          margin-bottom: 2px;
          font-size: 2.4vw;
        }

        &-title {
          font-size: 4.8vw;
        }
      }
    }

    &__main {
      &-item {
        &:not(:last-child) {
          margin-bottom: 29px;
        }
        &:last-child {
          margin-bottom: 71px;
        }
      }
    }

    &__header {
      &-card {
        justify-content: left;
        margin-bottom: 41px;
      }

      &-mir {
        margin-right: 32px;
      }

      &-item {
        &-title {
          font-size: 22px;
          line-height: 31px;
          margin-bottom: 3px;
        }

        &-btn {
          padding: 16px 0px;
          margin-bottom: 141px;
          &--up {
            margin-bottom: 64px;
          }
        }

        &-map {
          margin-top: 18px;
        }

        &-text {
          font-size: 18px;
          line-height: 31px;
        }
      }
    }
  }
}
