.program-list-main {
    &__img {
        width: 100%;
        margin-bottom: 83px;
    }

    @media screen and (max-width: 768px) {
        &__img {
            margin-bottom: 1.46vw;
        }
    }
    @media screen and (max-width: 428px) {
        &__title {
            font-size: 8vw;
        }
    }
}