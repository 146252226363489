.contacts-main {
  padding-top: 72px;
  padding-bottom: 234px;
  &__title {
    margin-bottom: 75px;
  }

  &__case {
    &-title {
      margin-bottom: 181px;
    }

    &-info {
      &--mobile {
        display: none;
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0 20px;
  }

  &__item {
    display: flex;
    max-width: 1021px;
    margin: 0 auto;
    &:not(:last-child) {
      margin-bottom: 82px;
    }
    &-map {
      overflow: hidden;
      margin-right: 35px;
      max-width: 548px;
      width: 100%;
      height: 290px;
      background-color: antiquewhite;
      border-radius: 10px;
    }

    &-title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 22px;
      line-height: 31px;
      color: #feed00;
    }

    &-text {
      font-weight: 300;
      font-size: 18px;
      line-height: 31px;
      color: #ffffff;
      &--tel {
        display: block;
      }
      &--mail {
        display: block;
        margin-bottom: 8px;
      }
    }

    &-caption {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #c2c2c2;
    }
    &-number {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #c2c2c2;
    }

    &-btn {
      cursor: pointer;
      display: block;
      text-align: center;
      background: #feed00;
      max-width: 438px;
      width: 100%;
      font-weight: 700;
      font-size: 21px;
      line-height: 20px;
      color: #000000;
      border-radius: 10px;
      padding: 18px 0;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      min-width: 357px;
    }
  }

  @media screen and (max-width: 1440px) {
    &__title {
        font-size: 5vw;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 25px;

    .slider-info {
      display: none;
    }

    &__case {
      &-title {
        margin-bottom: 72px;
      }
      &-info {
        &--mobile {
          display: block;
          margin: 0 34px;
        }
        &--desctop {
          display: none;
        }
      }
    }

    &__address {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
    }

    &__item {
      text-align: center;
      &-map {
        margin: 0 auto;
        margin-bottom: 16px;
        min-height: 189px;
        height: calc((100vw + 425px) / 4.5);
      }

      &-info {
        min-width: inherit;
      }

      &-text {
        &--mail {
          margin-bottom: 12px;
        }
      }

      &-btn {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}
