.video-detail-main {
  border-radius: 10px;

  &__video {
    width: 100%;
    // max-height: 800px;
    height: calc(100vw / 2);
    max-height: 700px;
  }

  &__body {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    padding-top: 60px;
    padding-bottom: 258px;
  }

  &__container {
    max-width: 1270px;
    margin: 0 auto;
  }

  &__date {
    &-container {
      margin-bottom: 59px;
      text-align: center;
    }
    display: inline-block;
    margin: 0 auto;
    padding: 14px 43px 14px 22px;
    background: #feed00;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }

  &__title {
    margin-bottom: 70px;
    font-weight: 700;
    font-size: 40px;
    line-height: 43px;
    text-align: center;
    color: #000000;
  }

  &__back {
    &-container {
      margin-bottom: 69px;
      display: block;
      font-weight: 400;
      font-size: 31px;
      line-height: 30px;
      text-align: center;
      text-decoration: underline;
      color: #787878;
      svg {
        margin-right: 50px;
        transform: rotate(90deg);
        fill: #787878;
        width: 32px;
        height: 17px;
      }
    }
  }


  @media screen and (max-width: 768px) {
    
    &__body {
        padding-top: 40px;
        padding-bottom: 60px;
    }
    &__back {
      &-container {
        margin-bottom: 30px;
        font-size: 21px;
        line-height: 20px;
        svg {
          margin-right: 25px;
          width: 17px;
          height: 9px;
        }
      }
    }

    &__date {
      padding: 0;
      width: 150px;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-container {
        margin-bottom: 24px;
      }
    }

    &__title {
      font-size: 29px;
      line-height: 33px;
      padding: 0 40px;
    }

    &__images {
      &-container {
        row-gap: 0px;
        column-gap: 0px;
        grid-template-columns: repeat(3, minmax(105px, 143px));
        img{
            max-width: 143px;
            max-height: 143px;
            width: calc(100vw / 3);
            height: calc(100vw / 3);
        }
      }
    }
  }
}
