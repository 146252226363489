.service-description-info {
  padding-top: 93px;
  &__description {
    max-width: 1570px;
    padding: 0px 16px;
    margin: 0 auto;
    margin-bottom: 70px;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }
  .swiper__service-description-info {
    padding-left: 20px;
    display: flex;
    margin-bottom: 105px;
  }

  .swiper-slide-desctop {
    width: 100%;
    height: 100%;
  }

  &__text {
    max-width: 1570px;
    padding: 0px 16px;
    margin: 0 auto;
    margin-bottom: 180px;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }

  
  @media screen and (max-width: 428px) {
    &__text {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 64px;
    }
    &__description {
      font-size: 18px;
      line-height: 22px;
    }
    .swiper-slide-desctop {
      border-radius: 10px;
      height: 46.73vw;
      object-fit: cover;
    }
  }
}
