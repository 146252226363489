.photo-main {
  padding-top: 80px;

  &__body {
    padding-top: 84px;
    padding-bottom: 209px;
    background-color: #ffffff;
    border-radius: 10px;
  }

  &__container {
    max-width: 1522px;
    margin: 0 auto;
    padding: 0px 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    row-gap: 74px;
    column-gap: 79px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    flex: 0 0 29%;
  }

  &__image {
    position: relative;

    img {
      border-radius: 10px;
      overflow: hidden;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__date {
    padding: 14px 43px 14px 22px;
    background: #feed00;
    position: absolute;
    bottom: 22px;
    left: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }

  &__text {
    height: 100%;
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  @media screen and (max-width: 768px) {
    padding-top: 12px;
    &__container {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
      row-gap: 53px;
      column-gap: 0px;
    }


    &__title {
      margin-bottom: 26px;
    }

    &__body {
      padding-top: 40px;
      padding-bottom: 98px;
    }
  }
}
