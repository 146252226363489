.service-description-services {
  padding-top: 134px;
  padding-bottom: 179px;
  &__case {
    text-align: center;
    margin-bottom: 80px;
  }
  &__title {
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    font-weight: 300;
    font-size: 40px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    padding-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      bottom: 0;
      width: calc(100% - 145px);
      height: 2px;
      background-color: #feed00;
    }
  }

  .swiper__service-description-services {
    padding-left: 37px;
    display: flex;
  }

  &__btn {
    background: #feed00;
    border-radius: 0px 0px 10px 10px;
    font-weight: 400;
    font-size: 25px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    padding: 20px 0px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 81px;
    padding-bottom: 99px;
    &__title {
      font-size: 20px;
      line-height: 19px;
    }
  }
  @media screen and (max-width: 425px) {
    .swiper__service-description-services {
      padding-left: 10px;
    }

    &__btn {
      font-size: 23px;
      line-height: 22px;
    }
  }
}
