.account-card__popup {
  max-width: 1408px;
  width: 100%;
  height: 922px;
}

.popup-display {
  display: none;
}

.fancybox__content > .carousel__button.is-close {
  top: 22px;
  right: 22px;
  width: 60px;
  height: 60px;
  background-image: url('../assets/images/close-popup.svg');
}

.fancybox__content > .carousel__button.is-close svg {
  display: none;
}

.popup-phone {
  max-width: 1203px;
  padding-top: 68px;
  width: 100%;
  height: 659px;

  &__error-text {
    color: #000000;
  }

  .application__input-error {
    background-color: #e9b5b0;
  }

  &__container {
    max-width: 527px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 17px;
    text-transform: uppercase;
    position: relative;
    font-weight: 400;
    font-size: 34px;
    line-height: 33px;
    text-align: center;
    color: #000000;
    padding-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      bottom: 0;
      width: calc(100% - 250px);
      height: 2px;
      background-color: #feed00;
    }
  }

  &__title-send {
    &-text {
      font-weight: 700;
      font-size: 27px;
      line-height: 28px;
      text-align: center;
      color: #222222;
      padding-bottom: 0;
      margin-bottom: 25px;

      &::after {
        display: none;
      }
    }

    &-description {
      max-width: 350px;
      font-weight: 400;
      font-size: 22px;
      line-height: 25px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  &__form {
    margin-bottom: 30px;
  }

  &__list {
    top: 55px;
  }

  &__btn {
    cursor: pointer;
    max-width: 360px;
    width: 100%;
    margin-top: 0;
    padding: 25px 0px;
  }

  &__error {
    font-size: 19px;
    line-height: 18px;
  }

  &__text {
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: #000000;
  }

  &__link {
    color: #000000;
  }

  &__icon {
    right: 30px;
    top: 22px;
    fill: #000000;
    width: 22px;
    height: 13px;
  }

  &__input {
    opacity: 1;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    border: 2px solid #757575;
    padding: 14.5px 53.5px 14.5px 19px;
    min-height: 55px;
    color: #303030;

    &::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 20px;
      line-height: 19px;
      color: #2c2c2c;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #191919;
    padding-left: 28px;
    padding-right: 28px;


    &__title-send {
      &-text {
        margin-top: 65px;
        color: #FEED00;
      }

      &-description {
        text-align: center;
        color: #FFFFF9;
      }
    }

    .application__title-case {
      padding: 0;
    }

    .application__input-error {
      background-color: rgba(244, 71, 71, 0.5);
    }

    &__text {
      color: #ffffff;
    }

    &__link {
      color: #ffffff;
    }

    &__btn {
      max-width: 100%;
      width: 100%;
    }

    &__icon {
      fill: #e6e6e6;
    }

    &__error {
      color: #ef2626;
    }

    &__description {
      display: none;
    }

    &__title {
      margin-bottom: 53px;
      font-size: 5.37vw;
      line-height: 22px;
      color: #ffffff;

      &::after {
        width: calc(100% * 0.66);
      }
    }

    &__input {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 21px;
      min-height: 59px;
      color: #e6e6e6;
      opacity: 1;
      border: 2px solid #757575;
      &::-webkit-input-placeholder {
        color: #757575;
        opacity: 0.7;
      }
    }

    &__form {
      padding: 0;
    }
  }

  @media screen and (max-width: 428px) {
    &__container {
      max-width: 100%;
      margin: 0;
    }

    .application__input-change {
      padding: 14.5px 60px 14.5px 19px;
    }

    &__input {
      font-size: 3.91vw;
      line-height: 5.67vw;
      &::-webkit-input-placeholder {
        font-size: 3.91vw;
        line-height: 5.44vw;
      }
    }

    &__btn {
      padding: 20px 0px;
    }

    &__text {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .fancybox__slide {
    padding: 0px;
  }

  .fancybox__content > .carousel__button.is-close {
    top: 25px !important;
    right: 25px !important;
    width: 38px;
    height: 38px;
    background-image: url('../assets/images/close-yellow-popup.svg');
  }
}
