.service-description-main {
    &__img {
        margin-bottom: 88px;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        &__img {
            margin-bottom: 1.46vw;
        }
    }
}