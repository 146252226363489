.slider-info {
  padding-left: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  &__link {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    text-align: left;
    background: #feed00;
    border-radius: 10px;
    width: auto;
    padding: 17px 0px;
    padding-left: 35px;
    padding-right: 36px;
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: #000000;

    transition: all 0.3s ease-in-out;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #149f55;
        color: #ffffff;
      }
    }

    &-text {
      pointer-events: none;
      width: 130px;
      margin-left: 14px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 17px;
      line-height: 16px;
      color: #000000;
    }

    &-svg {
      pointer-events: none;
    }
  }
  &__links {
    .swiper-slide {
      width: auto;
    }
  }

  @media screen and (max-width: 768px) {
    &__link {
      width: 180px;
      padding: 9px 0px;
      padding-left: 20px;
      padding-right: 0;
      align-items: center;

      &-text {
        font-size: 14px;
        line-height: 13px;
        margin-left: 2px;
      }
      &-svg {
        margin-right: 12px;
        flex: 0 0 auto;
        display: inline-block;
        width: 27px;
        height: 27px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 428px) {
    &__link {
      height: 55px;
      width: 42.06vw;
      padding: 2.10vw 0px;
      padding-left: 3.67vw;
      &-text {
        font-size: 3.27vw;
        line-height: 3.04vw;
      }
    }
  }
}

.main {
  &-title {
    margin-bottom: 72px;
    font-weight: 700;
    font-size: 61px;
    line-height: 58px;
    text-align: center;
    color: #ffffff;
  }

  &-btn {
    cursor: pointer;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 11px;
    max-width: 466px;
    width: 100%;
    padding: 27px 0px;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    background: #149f55;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #feed00;
        color: black;
      }
    }
  }

  &-description {
    margin-bottom: 38px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    &-title {
      margin-bottom: 46px;
      font-size: 31px;
    }

    &-description {
      font-size: 14px;
      line-height: 13px;
      margin-bottom: 22px;
    }

    &-btn {
      max-width: 360px;
      font-size: 18px;
      line-height: 20px;
      padding: 21px 0px;
      margin-top: 14px;
      margin-bottom: 6px;
    }
  }
}

.cookie {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 424px;
  z-index: 10000;
  &__container {
    position: relative;
    height: 100%;
    padding: 37px 46px 33px 44px;
    border-radius: 4px;
    background-color: #e2e2e2;

  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 16px;
    width: 21px;
    height: 21px;
  }

  &__text {
    margin-bottom: 19px;
    color: #000;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
  }

  &__link {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
  }

  &__btn {
    cursor: pointer;
    border-radius: 10px;
    background-color: #149f55;
    color: #fff;
    font-size: 14px;
    padding: 19px 35px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    margin: 0 auto;
    transition: all .3s ease-in-out;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color:  #feed00;
        color: #000;
        transition: all .3s ease-in-out;
      }
    }
  }

  @media screen and (max-width: 428px) {
    right: 0;
    bottom: 0;
    width: 100%;
    &__text {
      padding-right: 35px;
      margin-bottom: 11px;
      font-size: 10px;
      line-height: 16px;
      text-align: left;
    }
    &__container {
      border-radius: 0;
      padding: 12px 4px 11px 4px;
    }
    &__close {
      top: 10px;
      right: 8px;
    }

    &__btn {
      width: 250px;
      padding: 11.5px 35px;
    }
  }
}
