@font-face {
  font-family: 'Gotham Pro';
  src: url('/frontend/dist/assets/fonts/GothamPro-Light.woff2') format('woff2'), url('/frontend/dist/assets/fonts/GothamPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('/frontend/dist/assets/fonts/GothamPro-Medium.woff2') format('woff2'), url('/frontend/dist/assets/fonts/GothamPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('/frontend/dist/assets/fonts/GothamPro.woff2') format('woff2'), url('/frontend/dist/assets/fonts/GothamPro.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('/frontend/dist/assets/fonts/GothamPro-Bold.woff2') format('woff2'), url('/frontend/dist/assets/fonts/GothamPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('/frontend/dist/assets/fonts/GothamPro-Black.woff2') format('woff2'), url('/frontend/dist/assets/fonts/GothamPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

