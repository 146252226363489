.directions-traning-slider {
  &__traning {
    display: block;
  }

  .swiper__directions-link {
    margin-bottom: 57px;
    display: flex;
    // padding-left: 20px;
    .swiper-slide {
      opacity: 0.4;
      text-align: center;
      &-active {
        opacity: 1;
      }
    }
  }
  .swiper__directions-image {
    // height: 910px;
    margin-bottom: 20px;
    display: flex;
    // padding-left: 20px;

    .swiper-wrapper {
      margin-bottom: 40px;
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-pagination-bullet {
      opacity: 1;
      width: 13px;
      height: 12px;
      background: #999999;
      border: 1px solid #868686;
    }

    .swiper-pagination-bullet-active {
      background: #ffffff;
    }

    &-title {
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 30px;
      line-height: 29px;
      color: #ffffff;
    }

    &-text {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      color: #aeaeae;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-item {
      height: 35px;
      display: flex;
      align-items: center;
      &-container {
        display: inline-block;
        height: 35px;
        width: 35px;
        margin-right: 14px;
      }

      &-svg {
        width: 100%;
        height: 100%;
      }
    }

    &-body {
      text-align: center;
      max-width: 1084px;
      margin: 0 auto;
      width: 100%;
      transition: all 0.3s ease-in-out;
    }
    .swiper-slide,
    .swiper-slide-duplicate{
      width: auto;
      transition: all 0.3s ease-in-out;
      opacity: 0.6;
      .swiper__directions-image-body {
        opacity: 0;
      }

      .swiper__directions-image-picture {
        margin-bottom: 38px;
        overflow: hidden;
        border-radius: 10px;
        // height: calc((100vw + 1440px) / 5.5);
        // max-height: 611px;
        object-fit: cover;
        height: 31.82vw;
        width: 100%;
      }
    }

    .swiper-slide-active,
    .swiper-slide-duplicate-active{
      opacity: 1;
      transition: all 0.3s ease-in-out;
      .swiper__directions-image-body {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .swiper {
      &__directions {
        &-image {
          // height: calc((100vw + 1024px) / 3.1);
          .swiper-slide {
            // height: calc((100vw + 1024px) / 5.3);
          }

          &-body {
            padding: 0px 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .swiper {
      &__directions {
        &-image {
          &-container {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .swiper {
      &__directions {
        &-image {
          &-text {
            font-size: 1.9vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .swiper {
      &__directions {
        &-image {
          .swiper-slide {
            .swiper__directions-image-picture {
              margin-bottom: 16px;
              height:56.38vw;
            }
          }
          &-title {
            display: none;
          }

          &-text {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .swiper__directions-link {
      margin-bottom: 7.42vw;
    }

  }

  @media screen and (max-width: 428px) {
    .swiper {
      &__directions {
        &-image {
          margin-bottom: 20px;

          &-item {
            font-weight: 700;
            font-size: 4.1vw;
            line-height: 19px;
            &:not(:last-child) {
              margin-bottom: 9px;
            }
          }
          &-text {
            margin-bottom: 23px;
            font-size: 3.4vw;
          }
        }
      }
    }

    .swiper__directions-link {
      margin-bottom: 46px;
    }
  }
}
