.about {
  padding-top: 143px;
  padding-bottom: 183px;
  &__title {
    &-case {
      margin-bottom: 83px;
      text-align: center;
    }

    &-text {
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 60px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  &__case {
    &-one {
      text-align: center;
      max-width: 580px;
      padding-top: 21px;
      &-img {
        margin-bottom: 12px;
      }

      &-app-store {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 30px;
        position: relative;
        color: #ffffff;
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        &-download {
          margin-bottom: 6px;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
        }

        &-title {
          font-size: 22px;
        }
      }

      &-application {
        display: flex;
        justify-content: center;
      }

      &-play-market {
        display: flex;
        cursor: pointer;
        align-items: center;
        position: relative;
        color: #ffffff;
        img {
          margin-right: 10px;
          width: 40px;
          height: 40px;
        }

        &-download {
          text-align: start;
          margin-bottom: 6px;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
        }

        &-title {
          font-size: 20px;
        }
      }
      &-title {
        font-weight: 700;
        font-size: 33px;
        line-height: 50px;
        text-transform: uppercase;
        color: #ffffff;
      }

      &-description {
        max-width: 420px;
        margin: 0 auto;
        margin-bottom: 50px;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    &-two {
      width: 546px;
      padding: 67px 120px 73px 80px;
      background-color: #feed00;
      border-radius: 50px;

      &-title {
        margin-bottom: 21px;
        font-weight: 700;
        font-size: 41px;
        line-height: 50px;
        text-transform: uppercase;
        color: #000000;
      }

      &-social {
        list-style: none;
        display: flex;
        justify-content: space-between;
      }

      &-list {
        margin-bottom: 44px;
      }

      &-item {
        font-weight: 300;
        font-size: 20px;
        line-height: 33px;
        text-transform: uppercase;
        color: #000000;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1168px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1130px) {
    &__body {
      align-items: center;
      flex-direction: column;
    }

    &__case {
      &-one {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
