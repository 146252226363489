.stocks-main {
  padding-top: 115px;
  padding-bottom: 125px;


  &__body {
    padding-left: 16px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 24px;
    padding-bottom: 27px;
    .swiper__links {
      height: 60px;
    }

    &__title {
        margin-bottom: 46px;
        font-size: 31px;
    }

    &__description {
      font-size: 14px;
      line-height: 13px;
      margin-bottom: 22px;
    }

    &__btn {
      max-width: 360px;
      width: 100%;
      font-size: 18px;
      line-height: 20px;
      padding: 16px 0px;
      margin-top: 14px;
      margin-bottom: 6px;
    }
  }
  @media screen and (max-width: 428px) {
    margin-bottom: 19px;
  }
}
