.services-list-info {
  padding-top: 92px;
  padding-bottom: 143px;
  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__list {
    padding: 0px 10px;
    list-style: none;
    max-width: 1568px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    row-gap: 64px;
    column-gap: 51px;
    text-align: center;
  }

  &__img {
    transition: all 0.3s ease-in-out;
    width: 100%;
    object-fit: cover;
  }

  &__item {
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .services-list-info__img {
          transition: all 0.3s ease-in-out;
          opacity: 0.8;
        }
        .services-list-info__btn {
          font-weight: 700;
          transition: all 0.3s ease-in-out;
          background: #feed00;
          color: #000000;
        }
      }
    }
  }

  &__btn {
    height: 100%;
    padding: 23px 0px;
    font-weight: 400;
    font-size: 25px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 1024px) {
    &__list {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      row-gap: 64px;
      column-gap: 51px;
    }
  }
  @media screen and (max-width: 768px) {
    &__item {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .services-list-info__img {
            opacity: 1;
          }
        }
      }
    }
    &__btn {
      font-weight: 700;
      transition: all 0.3s ease-in-out;
      background: #feed00;
      color: #000000;
    }
  }
}
