.account-main {
  padding-top: 88px;

  &__address {
    font-size: 27px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    background: #feed00;
    padding: 20px 0px;

    &--bold {
      font-weight: 700;
    }
  }
  &__case {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
    &-name {
      margin-right: 60px;
      font-weight: 300;
      font-size: 26px;
      line-height: 25px;
      color: #ffffff;
    }

    &-tel {
      margin-right: 60px;
      font-weight: 700;
      font-size: 24px;
      line-height: 42px;
      color: #ffffff;
    }
    &-mail {
      position: relative;
      font-weight: 300;
      font-size: 24px;
      line-height: 23px;
      color: #a9a9a9;
      &::before {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #a9a9a9;
      }
    }
  }

  &__authorized-btn {
    display: none;
  }


  @media screen and (max-width: 1024px) {
    &__case {
      &-name {
        font-size: 2.54vw;
        line-height: 2.44vw;
      }
      &-tel {
        font-size: 2.34vw;
        line-height: 2.25vw;
      }

      &-mail {
        font-size: 2.34vw;
        line-height: 2.25vw;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__address {
      font-size: 4.67vmin;
    }

    &__case {
      flex-direction: column;
        justify-content: center;
      &-name {
        font-size: 6.07vmin;
        margin-right: 0;
        margin-bottom: 5.84vmin;
      }
      &-tel {
        font-size: 4.91vmin;
        line-height: 9.81vmin;
        margin-right: 0;
        margin-bottom: 2.34vmin;
      }
      &-mail {
        font-size: 4.91vmin;
        margin-bottom: 9.35vmin;
      }
    }
    &__authorized-btn {
      display: block;

      &-text {
        font-size: 5.37vmin;
        font-weight: 400;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }
}
