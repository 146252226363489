.gallery {
  padding-top: 145px;
  padding-bottom: 215px;
  &__traning {
    display: none;
    &--active {
      display: block;
    }
  }

  &__description {
    font-weight: 300;
    font-size: 48px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    &-bold {
      font-weight: 700;
    }

    &-yellow {
      font-weight: 700;
      color: #feed00;
    }
  }

  &__link {
    &-text {
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 21px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #feed00;
        }
      }
    }
  }
  .swiper__gallery-link {
    // margin-bottom: 52px;
    margin-bottom: 2.71vw;
    display: flex;
    // padding-left: 20px;
    .swiper-slide {
      width: auto;
      opacity: 0.4;
      text-align: center;
      &-active {
        opacity: 1;
      }
    }
  }

  .swiper__gallery-image {
    height: 40.63vw;
    margin-bottom: 7.81vw;
    // height: 789px;
    // margin-bottom: 150px;
    display: flex;

    .swiper__gallery-image-desctop {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    .swiper__gallery-image-mobile {
      display: none;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .swiper-pagination {
      bottom: 0px;
    }

    .swiper-pagination-bullet {
      opacity: 1;
      width: 0.68vw;
      height: 0.63vw;
      background: #999999;
      border: 1px solid #868686;
    }

    .swiper-pagination-bullet-active {
      background: #ffffff;
    }

    .swiper-slide,
    .swiper-slide-duplicate {
      width: auto;
      overflow: hidden;
      border-radius: 10px;
      height: 38.49vw;
      transition: all 0.3s ease-in-out;
      opacity: 0.6;

      .swiper__gallery-image-one {
        display: flex;
        height: 18.85vw;
        img {
          &:nth-child(1) {
            margin-right: 16px;
            flex: 32.5%;
            object-fit: cover;
          }
          &:nth-child(2) {
            flex: 67.5%;
          }
        }
      }
      .swiper__gallery-image-two {
        display: flex;
        height: 18.85vw;
        img {
          object-fit: cover;
          &:nth-child(1) {
            margin-right: 16px;
            flex: 67.5%;
          }
          &:nth-child(2) {
            flex: 32.5%;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-slide-active,
    .swiper-slide-duplicate-active{
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }

  &__title {
    &-case {
      margin-bottom: 99px;
      text-align: center;
    }
    &-text {
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      color: #ffffff;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 70px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 59px;
    .swiper__gallery-image {
      margin-bottom: 55px;
      height: 67.84vw;
      .swiper-slide {
        height: 63.93vw;
      }
      .swiper__gallery-image-desctop {
        display: none;
      }
      .swiper__gallery-image-mobile {
        display: block;
      }
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
      }
    }

    padding-top: 42px;
    &__title {
      &-case {
        margin-bottom: 51px;
      }

      &-text {
        font-size: 20px;
        line-height: 19px;
      }
    }

    &__link-text {
      font-size: 19px;
      line-height: 18px;
    }

    &__description {
      max-width: 428px;
      margin: 0 auto;
      font-size: 29px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 428px) {
    padding-bottom: 67px;
    &__title {
      &-case {
        margin-bottom: 44px;
      }
      &-text {
        &::after {
          width: 100%;
        }
      }
    }

    &__link {
      &-text {
        font-size: 4.21vw;
        line-height: 3.97vw;
      }
    }

    &__description {
      font-size: 6vw;
    }
    .swiper__gallery-link {
      margin-bottom: 11.21vw;
      // margin-bottom: 48px;
    }
    .swiper__gallery-image {
      height: 283px;
      margin-bottom: 44px;

      .swiper__gallery-image-desctop {
        display: none;
      }
      .swiper__gallery-image-mobile {
        display: block;
      }

      .swiper-slide {
        height: 240px;
      }
    }
  }
}
