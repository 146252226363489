.vacancy-detail-main {
  padding-top: 40px;
  &__body {
    padding-top: 105px;
    padding-bottom: 235px;
    background-color: #ffffff;
    border-radius: 10px;
  }

  &__container {
    max-width: 1247px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__back {
    &-container {
      font-weight: 400;
      font-size: 31px;
      line-height: 30px;
      margin-bottom: 102px;
      display: block;
      text-align: center;
      text-decoration: underline;
      color: #787878;
      svg {
        margin-right: 10px;
        transform: rotate(90deg);
        fill: #808080;
        width: 32px;
        height: 17px;
      }
    }
  }

  &__caption {
    font-weight: 400;
    font-size: 41px;
    line-height: 39px;
    text-align: center;
    color: #000000;
  }
  &__list {
    margin-top: 83px;
    margin-bottom: 115px;
    list-style: none;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 42px;
    }
    &-title {
      margin-bottom: 42px;
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      color: #000000;
    }

    &-list {
      list-style: none;
      padding-left: 15px;
      &--address {
        padding-left: 0px;
        li {
          padding-left: 0;
          &::before {
            display: none;
          }
        }
      }
    }

    &-text {
      padding-left: 20px;
      position: relative;
      font-weight: 300;
      font-size: 24px;
      line-height: 31px;
      color: #000000;

      &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: #000000;
        left: 0;
        top: 50%;
      }
    }
  }

  &__phone {
    margin-top: 18px;
    margin-bottom: 31px;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 37px;
    line-height: 35px;
    color: #000000;
  }
  &__mail {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    line-height: 29px;
    text-decoration: underline;
    color: #000000;
  }

  @media screen and (max-width: 768px) {
    &__body {
      padding-top: 42px;
      padding-bottom: 112px;
    }

    &__list {
      margin-top: 10.81vw;
      margin-bottom: 14.97vw;
    }

    &__back {
      &-container {
        margin-bottom: 13.28vw;
        font-size: 4.04vw;
        line-height: 3.91vw;
        svg {
          width: 17px;
          height: 9px;
        }
      }
    }

    &__caption {
      font-size: 5.34vw;
      line-height: 5.08vw;
    }

    &__item {

      &:not(:last-child ) {
        margin-bottom: 5.47vw;
      }
      &-title {
        margin-bottom: 5.47vw;
        font-size: 3.13vw;
        line-height: 4.04vw;
      }

      &-text {
        font-size: 3.13vw;
        line-height: 4.04vw;
      }
    }

    &__phone {
      font-size: 4.82vw;
      line-height: 4.56vw;
    }

    &__mail {
      font-size: 3.91vw;
      line-height: 3.78vw;
    }
  }
  @media screen and (max-width: 428px) {
    &__item {
      &-text {
        &::before{
          width: 1.17vw;
          height: 1.17vw;
        }
      }
    }
  }
}
