.stocks-information {
  padding-bottom: 226px;
  &__list {
    padding: 0 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 125px;
    }
  }

  &__img {
    margin-bottom: 20px;
    &-mobile {
      display: none;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    margin-right: 33px;
    font-weight: 400;
    font-size: 28px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }

  &__btn {
    cursor: pointer;
    max-width: 428px;
    width: 100%;
    padding: 26px 0px;
    background: #01a1e2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #feed00;
        color: black;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    &__text {
      font-size: 2vw;
    }
  }
  @media screen and (max-width: 1024px) {
    &__text {
      font-size: 1.7vw;
    }
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 73px;
    &__text {
      display: none;
    }

    &__item {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 21px;
      }
    }

    &__img {
      display: none;
      margin-bottom: 0;
      &-mobile {
        display: block;
        width: 100%;
        object-fit: cover;
      }
    }

    &__btn {
      font-size: 23px;
      font-weight: 300;
      border-radius: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      max-width: none;
    }
  }
}
