.swiper {
  .account-card__item-time {
    display: none;
    margin-top: -15px;
    margin-bottom: 40px;
  }
  &__main {
    // height: 40.94vw;
    height: auto;
    margin-bottom: 1.30vw;
    .swiper-slide {
      overflow: hidden;
      &-mobile {
        display: none;
        width: 100%;
        height: 100vw;
        margin-bottom: 30px;
      }

      &-desctop {
        width: 100%;
        // height: 100%;
        margin-bottom: 30px;
      }
      // height: 39.06vw;
      height: auto;
      border-radius: 10px;
      opacity: 0.5;
      transition: all 0.5s ease-in-out;
      &-active{
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
    }
    .swiper-slide-duplicate-active {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }

    .swiper-pagination {
      bottom: 0;
      &-bullet {
        opacity: 1;
        width: 0.68vw;
        height: 0.63vw;
        background: #999999;
        border: 1px solid #868686;
        &-active {
          background: #ffffff;
        }
      }
    }
  }

  &__links {
    .swiper-slide {
      width: auto;
    }
  }
  @media screen and (max-width: 428px) {
    &__main {
      // height: 106.18vw;
      height: auto;
      .swiper-slide {
        // height: 100vw;
        height: auto;
        border-radius: 0px;
        &-mobile {
          display: block;
        }

        &-desctop {
          display: none;
        }
      }

      .swiper-pagination {
        &-bullet {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.slider {
  margin-bottom: 157px;
  
  @media screen and (max-width: 1024px) {
    &__btn {
      max-width: 360px;
      width: 100%;
      font-size: 21px;
      line-height: 20px;
      padding: 21px 0px;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 6px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
    .swiper__links {
      height: 60px;
    }
    &__description {
      font-size: 14px;
      line-height: 13px;
      margin-bottom: 22px;
    }
  }

  @media screen and (max-width: 428px) {
    margin-bottom: 19px;

    &__btn {
      font-size: 4.91vw;
      line-height: 4.67vw;
      width: 90vw;
    }
  }
}
