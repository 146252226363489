.header {
  position: sticky;
  z-index: 20;
  top: 0;
  background-color: #000000;

  &__authorized {
    text-align: right;
    &-name {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 0.9vw;
      line-height: 22px;
      color: #feed00;
    }

    &-btn {
      cursor: pointer;
      font-weight: 400;
      font-size: 0.9vw;
      line-height: 22px;
      text-transform: uppercase;
      &-text {
        position: relative;
        margin-right: 8px;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #fff;
        }
      }
    }
  }
  &__mobile {
    display: none;

    &-change-fitnes {
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      background-color: #feed00;
      font-weight: 300;
      font-size: 3.8vw;
      line-height: 17px;
      color: #000000;
      height: 35px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &-item {
        position: relative;
        cursor: pointer;
        padding: 8px 0px;
        border: 1px solid rgba(0, 0, 0, 0.8);

        a {
          color: #000000;
        }

        svg {
          transform: scale(0.7);
          pointer-events: none;
          fill: #000000;
        }

        &-text {
          pointer-events: none;
          display: inline-block;
          position: relative;
          margin-right: 5px;
        }
        &-main {
          padding: 8px 0px;
          &--active {
            color: #feed00;
            background-color: #000000;
          }
        }
      }
    }

    &-head {
      height: 81px;
      margin-left: 4px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-phone {
      font-weight: 300;
      font-size: 4.7vw;
      line-height: 24px;
      color: #cecece;
    }

    &-burger {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 34px;
      width: 40px;
      position: relative;
      &-line {
        display: block;
        border-radius: 20px;
        background-color: #feed00;
        width: 40px;
        height: 6px;

        &--none {
          display: none;
        }

        &--rotate {
          &-one {
            position: absolute;
            transform: rotate(44deg);
            width: 44px;
            height: 5px;
            top: 16px;
            left: -4px;
          }
          &-two {
            position: absolute;
            transform: rotate(-44deg);
            width: 44px;
            height: 5px;
            top: 16px;
            left: -4px;
          }
        }
      }
    }
  }

  &__desctop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 11px 4px;
  }

  &__btn {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    // font-size: 0.94vw;
    // line-height: 0.89vw;
    font-size: 18px;
    line-height: 17px;
    border-radius: 16px;
    width: 257px;
    // width: 13.39vw;
    padding: 20.5px 0px;
    transition: all 0.3s ease-in-out;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 74%;
    margin-left: 51px;
    margin-right: 35px;
    padding-top: 25px;
    padding-bottom: 16px;

    &-number {
      font-weight: 700;
      font-size: 1.7vw;
      line-height: 37px;
      text-align: center;
      color: #feed00;
      transition: all 0.3s ease-in-out;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: white;
          opacity: 0.6;
        }
      }
    }

    &-phone {
      cursor: pointer;
      display: flex;
      align-items: end;
      text-transform: uppercase;
      position: relative;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #feed00;
      transition: all 0.3s ease-in-out;
      svg {
        pointer-events: none;
        fill: #feed00;
        margin-right: 5px;
        transition: all 0.3s ease-in-out;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: white;
          opacity: 0.6;
          svg {
            fill: white;
          }
        }
      }

      &-text {
        pointer-events: none;
      }
    }

    &-up {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 39px;

      &-social {
        &-list {
          display: flex;
          list-style: none;
        }
        &-item {
          &-text {
            display: flex;
            align-items: center;
          }
          &-inst {
            cursor: pointer;
            margin-right: 38px;
            fill: white;
            fill-opacity: 0.6;
            transition: all 0.3s ease-in-out;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                fill: #feed00;
                fill-opacity: 1;
              }
            }
          }
          &-vk {
            cursor: pointer;
            margin-right: 33px;
            fill: white;
            fill-opacity: 0.6;
            transition: all 0.3s ease-in-out;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                fill: #feed00;
                fill-opacity: 1;
              }
            }
          }

          &-tg {
            cursor: pointer;
            fill: white;
            fill-opacity: 0.6;
            transition: all 0.3s ease-in-out;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                fill: #feed00;
                fill-opacity: 1;
              }
            }
          }
        }
      }

      &-list {
        position: relative;
        color: #ffffff;
        list-style: none;

        &-drop {
          z-index: 5;
          opacity: 0;
          height: 0;
          overflow: hidden;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          // padding: 0 10px;
          width: 100%;
          background-color: #feed00;
          position: absolute;
          top: 36px;
          left: 0;
          transition: all 0.3s ease-in-out;
          // .header__body-up-item {
          //   color: #000000;
          //   margin-bottom: 10px;
          // }

          &--active {
            padding-top: 0px;
            opacity: 1;
          }

          &-item {
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: #9b9b9b;
                .header__body-up-item-link {
                  color: #feed00;
                }
              }
            }
          }
        }
      }

      &-item {
        z-index: 6;
        display: flex;
        cursor: pointer;
        align-items: center;
        font-weight: 400;
        font-size: 0.85vw;
        line-height: 17px;
        text-transform: uppercase;
        width: 100%;

        &-text {
          pointer-events: none;
        }

        &-link {
          color: #000000;
          padding: 10px 0px 10px 5px;
          width: 100%;
          transition: all 0.3s ease-in-out;
        }
        &-disabled {
          cursor: pointer;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 10px 10px;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
          &--active {
            .header__body-up-item-text {
              svg {
                fill: #000000;
                transform: rotate(180deg);
              }
            }
            color: #000000;
            background-color: #feed00;
          }
        }

        svg {
          width: 22px;
          height: 13px;
          fill: white;
          margin-right: 5px;
          transition: all 0.3s ease-in-out;
        }
        transition: all 0.3s ease-in-out;
        // &:hover {
        //   color: #ffffff;
        //   svg {
        //     fill: #ffffff;
        //   }
        // }
      }
    }
    &-down {
      margin-left: 30px;
      margin-right: 30px;
      &-list {
        list-style: none;
        display: flex;
        justify-content: space-between;
      }

      &-sub {
        &-list {
          display: none;
          width: 120%;
          border-radius: 10px;
          padding: 7px;
          left: -7px;
          padding-top: 10px;
          background-color: #000000;
          position: absolute;
          list-style: none;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              display: block;
            }
          }
        }

        &-item {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          &-link {
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 700;
            // font-size: 20px;
            // line-height: 23px;
            font-size: 1.2vw;
            line-height: 1.4vw;
            color: #ffffff;

            transition: all 0.3s ease-in-out;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #feed00;
              }
            }
          }
        }
      }

      &-item {
        position: relative;
        &-link {
          display: flex;
          align-items: center;
          svg {
            width: 22px;
            height: 13px;
            margin-left: 5px;
            fill: #ffffff;
            transition: all 0.3s ease-in-out;
          }
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 800;
          // font-size: 24px;
          // line-height: 23px;
          font-size: 1.25vw;
          line-height: 1.2vw;
          color: #ffffff;

          transition: all 0.3s ease-in-out;
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #feed00;
            }
          }
        }
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .header__body-down-sub-list {
              display: block;
            }
            .header__body-down-item-link svg {
              fill: #feed00;
              transform: rotate(180deg);
              transition: all 0.3s ease-in-out;
            }
            .header__body-down-item-link {
              color: #feed00;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    &__logo {
      img {
        width: 120px;
      }
    }
    &__body {
      margin-left: 15px;
      &-up {
        &-list {
        }
        &-item {
          font-size: 0.85vw;
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              .header__body-up-item-text svg {
                transform: rotate(180deg) scale(0.9);
              }
            }
          }
          svg {
            transform: scale(0.9);
          }
        }
        &-social {
          &-item {
            &-inst {
              margin-right: 15px;
            }
            &-vk {
              margin-right: 15px;
            }
          }
        }
      }
      &-number {
        font-size: 1.5vw;
      }

      &-phone {
        font-size: 14px;
      }

      &-down {
        &-item {
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              .header__body-down-item-link svg {
                transform: rotate(180deg) scale(0.9);
              }
            }
          }
          svg {
            transform: scale(0.9);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__btn {
      font-size: 14px;
      width: 200px;
      padding: 14px 0px;
    }

    &__authorized {
      padding-top: 15px;
      &-name {
        margin-bottom: 2;
        line-height: 15px;
      }
      &-btn {
        svg {
          width: 18px;
          height: 20px;
        }
      }
    }

    &__body {
      &-up {
        margin-bottom: 20px;
        &-list {
        }
        &-item {
          font-size: 0.8vw;
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              .header__body-up-item-text svg {
                transform: rotate(180deg) scale(0.7);
              }
            }
          }
          svg {
            transform: scale(0.7);
          }
        }

        &-social {
          &-item {
            &-inst {
              margin-right: 10px;
              width: 25px;
            }
            &-vk {
              margin-right: 10px;
              width: 25px;
            }
            &-tg {
              width: 25px;
            }
          }
        }
      }
      &-number {
        font-size: 1.5vw;
      }

      &-phone {
        font-size: 12px;
      }

      &-down {
        &-item {
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              .header__body-down-item-link svg {
                transform: rotate(180deg) scale(0.7);
              }
            }
          }
          &-link {
            svg {
              transform: scale(0.7);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__logo {
      img {
        width: 90px;
      }
    }
    &__btn {
      font-size: 12px;
      width: 160px;
      padding: 11.5px 0px;
    }

    &__body {
      padding-top: 0;
      margin-left: 0px;
      margin-right: 5px;
      &-up {
        margin-bottom: 15px;
        &-list {
          &-drop {
            padding-top: 6px;
          }
        }
        &-item {
          font-size: 0.8vw;
          &-disabled {
            svg {
              transform: scale(0.6);
            }
          }
        }

        &-social {
          &-item {
            &-inst {
              width: 20px;
            }
            &-vk {
              width: 20px;
            }
            &-tg {
              width: 20px;
            }
          }
        }
      }
      &-number {
        font-size: 1.6vw;
      }

      &-phone {
        font-size: 9px;
        svg {
          transform: scale(0.8);
          margin-right: 0px;
        }
        &::before {
          top: -3px;
          transform: scale(0.5);
        }
      }

      &-down {
        &-item {
          &-link {
            font-size: 1.2vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__desctop {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }
}

.menu {
  display: none;
  z-index: 100;
  overflow-y: auto;
  left: -100%;
  padding: 32px 27px 20px;
  position: fixed;
  background-color: #000000;
  width: 100%;
  height: calc(100% - 116px);
  transition: all 0.5s ease-in-out;
  &--active {
    left: 0;
    transition: all 0.5s ease-in-out;
  }
  &-back{
    margin-top: -2px;
  }

  &__programm {
    display: none;
  }

  &__sub-list {
    display: none;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 55px;
    }

    &-title {
      margin-left: 15px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 32px;
      line-height: 31px;
      color: #aaaaaa;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 28px;
    line-height: 31px;
    color: #ffffff;
    margin-bottom: 40px;

    &-link {
      color: white;
    }

    &--arrow {
      display: flex;
      align-items: center;
      svg {
        margin-left: 20px;
        pointer-events: none;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 17px 0px;
    height: 60px;
    border-radius: 10px;
    line-height: 22px;
    text-transform: uppercase;

    &-text {
      color: #feed00;
    }

    svg {
      margin-right: 10px;
    }

    &--stroke {
      margin-bottom: 20px;
      font-weight: 300;
      font-size: 23px;
      color: #feed00;
      border: 2px solid #feed00;
    }

    &--yellow {
      font-weight: 400;
      font-size: 23px;
      color: #000000;
      background-color: #feed00;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
  @media screen and (max-width: 428px) {
    &__btn {
      font-size: 5.5vw;
    }
  }
}
