.vacancy-main {
  padding-top: 40px;
  &__title {
    margin-bottom: 80px;
  }

  &__container {
    background-color: #191919;
    border-radius: 10px;
    padding-top: 174px;
    padding-bottom: 237px;
  }

  &__list {
    max-width: 1456px;
    margin: 0 auto;
    padding: 0px 10px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    row-gap: 99px;
    column-gap: 116px;
  }

  &__item {
    &-link {
      display: flex;
      flex-direction: column;
    }
    &-container {
      height: 100%;
      min-height: 264px;
      padding: 33px 24px 72px;
      background: #ffffff;
      border-radius: 10px 10px 0px 0px;
    }

    &-title {
      margin-bottom: 44px;
      font-weight: 400;
      font-size: 27px;
      line-height: 31px;
      text-align: center;
      color: #000000;
    }

    &-text {
      font-weight: 400;
      font-size: 19px;
      line-height: 18px;
      text-align: center;
      color: #000000;
    }

    &-btn {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 21px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      background: #feed00;
      width: calc(100% - 20px);
      margin: 0 auto;
      margin-top: -50px;
      padding: 21px 0px;
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    &__list {
      grid-template-columns: repeat(auto-fill, minmax(341px, 1fr));
      row-gap: 39px;
      column-gap: 50px;
    }
  }
  @media screen and (max-width: 428px) {
    &__list {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }

    &__item {
      &-title {
        margin-bottom: 10.28vw;
        font-size: 5.37vw;
        line-height: 6.78vw;
      }

      &-text {
        font-size: 4.44vw;
        line-height: 4.21vw;
      }

      &-btn {
        font-size: 4.91vw;
        line-height: 4.67vw;
      }
    }
  }
}
