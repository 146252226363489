.service-description-privilege {
  padding-bottom: 135px;

  .swiper__service-description-privilege {
    padding: 0px 34px;
    display: flex;
    margin-bottom: 117px;
  }

  &__list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, 520px);
    justify-content: center;
    max-width: 1133px;
    margin: 0 auto;
    row-gap: 100px;
    column-gap: 90px;
    margin-bottom: 170px;
  }

  &__list-image {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1567px;
    padding: 0 10px;
    margin: 0 auto;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  &__description {
    padding: 0 24px;
    max-width: 1573px;
    margin: 0 auto;
    margin-bottom: 120px;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    &-svg {
      margin-right: 22px;
      opacity: 0.3;
      height: 80px;
      width: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-title {
      width: 386px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 39px;
      line-height: 41px;
      color: #ffffff;
    }

    &-text {
      width: 344px;
      font-weight: 300;
      font-size: 17px;
      line-height: 21px;
      color: #ffffff;
    }
  }

  &__text {
    padding: 0 24px;
    max-width: 1567px;
    margin: 0 auto;
    margin-bottom: 115px;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }

  &__btn {
    cursor: pointer;
    max-width: 559px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0px;
    background-color: #feed00;
    border-radius: 10px;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    text-transform: uppercase;
    text-align: center;
    color: #000000;
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 1024px) {
    &__list {
      row-gap: 40px;
      column-gap: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    &__list {
      display: flex;
      flex-direction: column;
      padding: 0px 24px;
    }
    .swiper__service-description-privilege {
      display: none;
    }

    &__list-image {
      margin-bottom: 63px;
      grid-template-columns: repeat(2, 1fr);
    }
    &__item {
      &-case {
        width: 100%;
      }
      &-title {
        width: auto;
        font-size: 5vw;
      }
      &-text {
        width: auto;
        max-width: 450px;
        font-size: 3.5vw;
      }
      &-svg {
        width: 150px;
      }
    }
  }
  @media screen and (max-width: 425px) {
    padding-bottom: 70px;
    &__item {
      &-svg {
        margin-right: 0;
        svg {
          width: 70px;
        }
      }
    }

    
    &__list-image {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 0;
      column-gap: 0;
      padding: 0;
    }

    &__description {
      font-size: 19px;
      line-height: 24px;
      margin-bottom: 60px;
    }

    &__list {
      margin-bottom: 70px;
    }

    &__text {
      font-size: 19px;
      line-height: 24px;
      margin-bottom: 60px;
    }

    &__btn {
      padding: 20px 0px;
      font-size: 20px;
      width: 260px;
      margin: 0 auto;
    }
  }
}
