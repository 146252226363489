.test {
  background-color: #161616;
  padding-top: 135px;
  padding-bottom: 169px;
  &__title {
    &-case {
      margin-bottom: 76px;
      text-align: center;
    }

    &-text {
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 60px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  &__form {
    max-width: 1254px;
    margin: 0 auto;

    &-error {
      margin-top: 10px;
      text-align: center;
      color: red;
    }

    &-stage {
      display: none;
      &--active {
        display: block;
      }
    }

    &-input {
      display: none;
    }

    // .test__form-input:checked + label::before {
    //   border-color: #FFFFFF;
    //   background-color: #282828;
    // }
    .test__form-input:checked + label::after {
      display: inline-block;
    }
    .test__form-input:checked + label {
      color: #feed00;
    }

    &-number {
      margin-top: 65px;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      text-align: center;
      color: #ffffff;
      opacity: 0.2;
    }

    &-btn {
      cursor: pointer;
      padding: 27px 0px;
      text-transform: uppercase;
      max-width: 466px;
      width: 100%;
      margin: 0 auto;
      margin-top: 75px;
      background-color: #018aaf;
      border-radius: 10px;
      font-weight: 700;
      font-size: 27px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #feed00;
          color: black;
        }
      }
    }

    &-title {
      margin-bottom: 60px;
      font-weight: 700;
      font-size: 47px;
      line-height: 68px;
      text-align: center;
      color: #ffffff;
      opacity: 0.3;
    }

    &-one {
      &-case {
        width: 590px;
        margin: 0 auto;
      }
    }
    &-two {
      &-case {
        width: 590px;
        margin: 0 auto;
      }
    }
    &-three {
      &-case {
        width: 590px;
        margin: 0 auto;
      }
    }
    &-four {
      &-caption {
        font-weight: 700;
        font-size: 41px;
        line-height: 39px;
        text-align: center;
        color: #ffffff;
      }

      &-input-case {
        max-width: 560px;
        margin: 0 auto;
      }

      &-title {
        // &-case {
        //   width: 590px;
        //   margin: 0 auto;
        // }
        font-size: 35px;
        line-height: 68px;
      }
    }

    &-body {
      &:not(:last-child) {
        margin-bottom: 23px;
      }
      //   padding-left: 170px;
      &-label {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 300;
        font-size: 28px;
        line-height: 48px;
        color: #ffffff;

        &::before {
          content: '';
          display: inline-block;
          width: 35px;
          height: 35px;
          flex-shrink: 0;
          flex-grow: 0;
          border: 1px solid #adb5bd;
          background: #282828;
          border-radius: 100%;
          margin-right: 0.5em;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
        }
        &::after {
          display: none;
          content: '';
          position: absolute;
          left: 8px;
          width: 19px;
          height: 19px;
          flex-shrink: 0;
          flex-grow: 0;
          background: #feed00;
          border-radius: 100%;
          margin-right: 0.5em;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
