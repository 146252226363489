.account-card {
  padding-top: 58px;
  padding-bottom: 141px;
  background: #262626;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  &__case {
    margin: 0 auto;
    margin-bottom: 67px;
    max-width: 512px;
    width: 100%;
    text-align: center;
  }
  &__btn {
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 12px;
    display: block;
    padding: 26.5px 0px;
    max-width: 512px;
    width: 100%;
    background: #01a1e2;
    border-radius: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #feed00;
        color: #000000;
      }
    }
  }

  &__list {
    list-style: none;
    max-width: 1360px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 76px;
    column-gap: 58px;
  }

  &__item {
    cursor: pointer;
    max-width: 408px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &--active {
      .account-card__item-case {
        background-color: #feed00;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .account-card__item-btn {
          background-color: #32974e;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-case {
      overflow: hidden;
      height: 100%;
      padding: 27px 28px 38px;
      color: #000000;
      background-color: #ffffff;
      border-radius: 10px 10px 0px 0px;
      transition: all 0.3s ease-in-out;
      &--img {
        padding: 0;
      }
    }

    &-price {
      font-weight: 700;
      font-size: 29px;
      line-height: 28px;
      &-case {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-sale {
        margin-left: 8px;
        font-weight: 400;
        font-size: 22px;
        line-height: 21px;
        text-decoration: line-through;
        color: #494949;
      }
    }

    &-btn {
      display: block;
      padding: 18px 0px;
      border-radius: 10px;
      text-align: center;
      max-width: 387px;
      width: 100%;
      margin: -25px auto 0px;
      background: #32974e;
      font-weight: 700;
      font-size: 21px;
      line-height: 20px;
      color: #ffffff;
    }

    &-info {
      &-list {
        list-style: none;
        text-align: start;
        margin-bottom: 37px;
      }
      &-item {
        &:not(:last-child) {
          margin-bottom: 18px;
        }
        img {
          margin-right: 14px;
        }
      }
    }

    &-title {
      margin-bottom: 26px;
      font-weight: 700;
      font-size: 24px;
      line-height: 23px;
      text-align: center;
    }

    &-case {
      text-align: center;
    }

    &-time {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 22px;
      &-case {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-points {
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        margin: 0 5px;
      }

      &-number {
        font-weight: 500;
        font-size: 32px;
        line-height: 30px;
      }

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__btn {
      padding: 5.14vmin 0px;
      font-size: 3.91vmin;
      max-width: calc(100% - 40px);
      &-text {
        font-size: 3.27vmin;
      }
    }
  }
  @media screen and (max-width: 428px) {
    &__btn {
      font-size: 4.91vw;
    }
  }
}
