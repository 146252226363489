.directions {
  padding-bottom: 146px;
  &__traning {
    display: block;
  }

  

  &__btn {
    cursor: pointer;
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    max-width: 466px;
    text-transform: uppercase;
    background: #feed00;
    border-radius: 10px;
    padding: 29px 0px;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    color: #000000;
  }


  &__title {
    &-case {
      margin-bottom: 116px;
      text-align: center;
    }
    &-text {
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      color: #ffffff;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 70px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  &__link {

    &-case {
      width: auto;
    }
    &-text {
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 23px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      // opacity: 0.4;
      transition: all 0.3s ease-in-out;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #feed00;
        }
      }
      // &--active {
      //   opacity: 1;
      // }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 59px;
    &__title {
      &-case {
        margin-bottom: 55px;
      }
      &-text {
        font-size: 20px;
        line-height: 19px;
      }
    }

    &__btn {
      padding: 20px 0px;
      max-width: 360px;
      font-size: 21px;
      line-height: 20px;
    }

    &__link{
      &-text {
        font-size: 2.99vw;
        line-height: 2.86vw;
      }
    }
  }

  @media screen and (max-width: 428px) {
    &__link {
      &-text {
        // font-size: 19px;
        font-size: 4.44vw;
      }
    }

    &__btn {
      padding: 16px 0px;
      width: 90vw;
    }

    &__title {
      &-case {
        margin-bottom: 48px;
      }
    }
    .swiper {
      &__directions {
        &-image {
          margin-bottom: 20px;

          &-item {
            font-weight: 700;
            font-size: 4.1vw;
            line-height: 19px;
            &:not(:last-child) {
              margin-bottom: 9px;
            }
          }
          &-text {
            font-size: 2.4vw;
            line-height: 15px;
            margin-bottom: 23px;
          }
        }
      }
    }

    .swiper__directions-link {
      // margin-bottom: 46px;
      margin-bottom: 10.75vw;
    }
  }
}
