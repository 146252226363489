.service-description-text {
  padding-top: 127px;
  padding-bottom: 71px;
  background-color: #ffffff;
  border-radius: 10px;
  &__text {
    max-width: 1570px;
    margin: 0 auto;
    padding: 0 20px;
    font-weight: 400;
    font-size: 25px;
    line-height: 42px;
    text-align: center;
    color: #000000;
  }

  @media screen and (max-width: 768px) {
    padding-top: 66px;
    padding-bottom: 59px;
    &__text {
      font-size: 4vw;
      line-height: 30px;

      &-item {
        display: block;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
  @media screen and (max-width: 425px) {
    &__text {
      font-size: 19px;
      line-height: 22px;
    }
  }
}
