.card {
  background: #ffffff;
  border-radius: 10px;
  padding-top: 106px;
  padding-bottom: 103px;
  color: #000000;
  .swiper__card {
    display: flex;
    margin-left: 9.01vw;
    height: 24.64vw;
  }

  .swiper-pagination {
    &-bullet {
      opacity: 1;
      width: 10px;
      height: 10px;
      background: #999999;
      border: 1px solid #868686;
      &-active {
        background: #bebebe;
      }
    }
  }
  &__title {
    &-case {
      margin-bottom: 116px;
      text-align: center;
    }

    &-text {
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      font-weight: 400;
      font-size: 40px;
      line-height: 38px;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 60px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  &__caption {
    margin-top: 29px;
    margin-bottom: 76px;
    text-align: center;
    font-weight: 300;
    font-size: 33px;
    line-height: 48px;
  }

  &__btn {
    display: block;
    padding: 29px 0px;
    max-width: 466px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-size: 27px;
    line-height: 26px;
    background: #feed00;
    border-radius: 10px;
    color: #000000;
    text-transform: uppercase;
  }

  &__item {
    width: auto;
    &:last-child {
      padding-top: 3.02vw;
    }
    &:not(:last-child) {
      margin-right: 7.24vw;
      width: 15.16vw;
    }
    &-title {
      margin-top: 2.03vw;
      margin-bottom: 2.66vw;
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 1.2vw;
    }

    &-text {
      margin-bottom: 1.93vw;
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 1.2vw;
    }

    &-description {
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 1.2vw;
    }

    &-bold {
      font-weight: 700;
    }

    &-number {
      font-weight: 700;
      font-size: 8.02vw;
      margin-bottom: 2.08vw;
      line-height: 7.66vw;
    }

    &-types {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 2.19vw;
      line-height: 2.08vw;
      width: 17.03vw;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 59px;
    &__item {
      &-picture {
        border-radius: 10px;
        overflow: hidden;
        height: 190px;
      }
      img {
        width: 292px;
        height: 100%;
        max-height: 190px;
        object-fit: cover;
      }
      &:not(:last-child) {
        margin-right: 35px;
        width: 330px;
      }

      &:last-child {
        padding-top: 100px;
      }

      &-number {
        font-size: 90px;
        line-height: 95px;
        margin-bottom: 30px;
      }

      &-types {
        font-size: 40px;
        line-height: 42px;
        width: 200px;
      }

      &-title {
        font-size: 24px;
        line-height: 23px;
        margin-top: 40px;
        margin-bottom: 34px;
      }
      &-text {
        margin-bottom: 34px;
        font-size: 24px;
        line-height: 23px;
      }
      &-description {
        font-size: 24px;
        line-height: 23px;
      }
    }
    &__title {
      &-case {
        margin-bottom: 40px;
      }
      &-text {
        font-size: 20px;
        line-height: 19px;
      }
    }

    &__caption {
      margin-top: 28px;
      margin-bottom: 25px;
      font-size: 23px;
      line-height: 29px;
    }

    &__btn {
      max-width: 360px;
      font-size: 21px;
      line-height: 20px;
      padding: 20px 0px;
    }

    .swiper__card {
      margin-left: 0px;
      height: 473px;
    }
    .swiper-slide {
      &:first-child {
        padding-left: 28px;
      }
    }
  }
  @media screen and (max-width: 428px) {
    padding-bottom: 79px;
    &__title {
      &-case {
        margin-bottom: 33px;
      }
    }

    &__btn {
      padding: 16px 0px;
      width: 90vw;
    }

    &__item {
      img {
        width: 68.22vw;
        height: 100%;
        max-height: 44.39vw;
      }
      &:not(:last-child) {
        margin-right: 35px;
        width: 330px;
      }

      &:last-child {
        padding-top: 20.02vw;
      }

      &-number {
        font-size: 23.36vw;
        margin-bottom: 10.08vw;
        line-height: 20.36vw;
      }

      &-types {
        font-size: 8.19vw;
        line-height: 9.08vw;
        width: 44.03vw;
      }

      &-title {
        margin-top: 9.35vw;
        margin-bottom: 7.94vw;
        font-size: 5.61vw;
        line-height: 5.37vw;
      }
      &-text {
        margin-bottom: 7.94vw;
        font-size: 5.61vw;
        line-height: 5.37vw;
      }
      &-description {
        font-size: 5.61vw;
        line-height: 5.37vw;
      }
    }


    &__caption {
      margin-top: 0px;
      margin-bottom: 31px;
      padding-left: 20px;
      font-size: 5vw;
    }
    .swiper__card {
      margin-bottom: 18px;
      height: 110.51vw;
    }

    .swiper-slide {
      &:first-child {
        padding-left: 28px;
      }
    }
  }
}
