/* ================================ Fonts ================================ */
@import './fonts.scss';

/* ================================ Animations ================================ */
@import './_animations/_vars.scss'; @import './_animations/keyframes/_[keyframeName].scss';

/* ================================ Styles ================================ */
@import './_styles/_colors.scss'; @import './_styles/_fonts.scss';

/* ================================ Reset ================================ */
@import './reset.scss';

/* ================================ Base ================================ */
@import './-base/_about.scss'; @import './-base/_account-card.scss'; @import './-base/_account-main.scss'; @import './-base/_application.scss'; @import './-base/_base-style.scss'; @import './-base/_card.scss'; @import './-base/_contacts-main.scss'; @import './-base/_directions-traning-slider.scss'; @import './-base/_directions.scss'; @import './-base/_erorr-main.scss'; @import './-base/_footer.scss'; @import './-base/_gallery.scss'; @import './-base/_header.scss'; @import './-base/_perosnal-data-main.scss'; @import './-base/_photo-detail-main.scss'; @import './-base/_photo-main.scss'; @import './-base/_popup.scss'; @import './-base/_privilege.scss'; @import './-base/_program-list-info.scss'; @import './-base/_program-list-main.scss'; @import './-base/_program-list-privilege.scss'; @import './-base/_service-description-info.scss'; @import './-base/_service-description-main.scss'; @import './-base/_service-description-privilege.scss'; @import './-base/_service-description-services.scss'; @import './-base/_service-description-text.scss'; @import './-base/_services-list-info.scss'; @import './-base/_services-list-main.scss'; @import './-base/_services-list-privilege.scss'; @import './-base/_stocks-directions.scss'; @import './-base/_stocks-information.scss'; @import './-base/_stocks-main.scss'; @import './-base/_stocks-privilege.scss'; @import './-base/_swiper.scss'; @import './-base/_test.scss'; @import './-base/_timetable-calendar.scss'; @import './-base/_timetable-main.scss'; @import './-base/_vacancy-detail-main.scss'; @import './-base/_vacancy-main.scss'; @import './-base/_video-detail-main.scss'; @import './-base/_video-main.scss';

/* ================================ Combinations ================================ */
@import './-combinations/_[className].scss';

/* ================================ Modules ================================ */
@import './-modules/_[className].scss';

/* ================================ Global Modifications ================================ */
@import './mods/_[className].scss';

body {
  font-family: 'Gotham Pro';
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  background-color: #000000;
}

.body {
  &--hidden {
    overflow: hidden;
  }
}

.btn--yellow {
  background-color: #feed00;
  text-align: center;
  color: #000000;
  transition: all 0.3s ease-in-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #32974e;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
    }
  }
}

.auth__form {
  max-width: 800px;
  width: 100%;
  padding: 0px 34px;
  margin: 0 auto;
  margin-top: 53px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth__container {
  background-color: #ffffff;
  padding-top: 20px;
}

.auth__container .main-title {
  color: #000000;
}

.auth__container .application__btn {
  margin: 0 auto;
  margin-bottom: 40px;
}

.auth__container-confirm .application__text {
  color: #000000;
}

.auth__container-confirm-text {
  margin-bottom: 15px;
  color: #000000;
}

.auth__container-form {
  width: 100%;
}

.auth__container-confirm-list {
  list-style: none;
}

.auth__container-form .popup-phone__input {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 21px;
  min-height: 59px;
  color: #303030;
  opacity: inherit;
  border: 2px solid #757575;
  &::-webkit-input-placeholder {
    color: #303030;
    opacity: 0.7;
  }
}

.pay__main {
  background-color: #ffffff;

  @media screen and (max-width: 768px)  {
    padding-top: 30px;
  }
}

.pay__main .account-main__title {
  margin-bottom: 0;
  color: #000000;
}

.pay__info {
  border-radius: inherit;
  background-color: #ffffff;
  @media screen and (max-width: 768px){
    padding-top: 1px;
  }
}

.pay__info .application__input {
  max-width: 515px;
  width: 100%;
}

.pay__info-title {
  margin-bottom: 17px;
  text-transform: uppercase;
  position: relative;
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  padding-bottom: 20px;
}

.pay__info-title::after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 0;
  width: calc(100% - 100px);
  height: 2px;
  background-color: #feed00;
}

.pay__info-description {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}

.pay__info .application__text {
  color: #000000;
  margin-bottom: 50px;
}
.pay__info .application__text-link {
  color: #000000;
}

.pay__info .application__text input {
  display: none;
}

.pay__info .pay__info-application-text {
  display: inline-flex;
  align-items: center;
  user-select: none;
  padding-left: 40px;
}

.pay__info .application__btn {
  margin-top: 20px;
}

.pay__info .popup-phone__input {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 21px;
  opacity: 1;
  font-weight: 400;
  border: 2px solid #757575;
  padding: 14.5px 53.5px 14.5px 19px;
  min-height: 55px;
  color: #303030;
  &::-webkit-input-placeholder {
    color: #303030;
    opacity: 0.7;
  }
}

@media screen and (max-width: 768px) {
  .pay__info-title {
    font-size: 5.21vw;
    line-height: 5.47vw;
  }
}

.pay__info-application-text input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.pay__info-application-text>span {
  position: relative;
}


.pay__info-application-text>span::before {
  cursor: pointer;
  content: '';
  position: absolute;
  left: -40px;
  top: -6px;
  width: 30px;
  height: 30px;
  border: 3px solid #999999;
  border-radius: 100%;
}

.pay__info-application-text>input:checked+span::after {
  cursor: pointer;
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #999999;
  left: -31px;
  top: 3px;
}

.pay__info-application-text.application__input-error {
  background-color: inherit;
  .pay__info-application-description {
    color: red;
  }
}

.pay__info-application--errors {
  list-style: none;
  li {
      margin-bottom: 15px;
  }
}

.pay-fail {
  max-width: 1001px;
  padding: 20px;
  margin-top: 0;
  .account-main__title {
    color: #000;
    font-size: 3.00vw;
    font-weight: 400;
    line-height: 3.10vw;
  }

  &__phone {
    color: #000;
    font-size: 1.71vw;
    font-weight: 700;
  }


  @media screen and (max-width: 768px) {
    .account-main__title {
      font-size: 34px;
      line-height: 36px;
    }

    .application__text {
      text-align: center;
      font-size: 21px;
      line-height: 22px;
    }

    &__phone {
      font-size: 28px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .account-main__title {
      font-size: 7.94vw;
      line-height: 8.41vw;
    }

    .application__text {
      text-align: center;
      font-size: 3.7vw;
      line-height: 4.14vw;
    }

    &__phone {
      font-size: 6.54vw !important;
    }
  }
}


.pay-success {
  max-width: 1158px;
  padding: 0 20px;
  .application__text {
    color: #000;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
  }
  &__title {
    text-align: center;
    font-size: 44px !important;
    font-weight: 400 !important;
    line-height: normal;
  }

  &__caption {
    color: #000;
    text-align: center;
    font-size: 26px !important;
  }

  &__phone {
    color: #000;
    font-size: 1.71vw !important;
    font-weight: 700 !important;
  }

  &__order {
    text-align: center;
    font-size: 44px !important;
  }

  @media screen and (max-width: 768px){
    .application__text {
      color: #000;
      font-size: 4.91vmin;
      line-height: 5.14vmin;
    }

    &__order {
      font-size: 6.07vmin !important;
    }
    &__title {
      font-size: 7.94vmin !important;
      line-height: 8.41vmin !important;
    }

    &__caption {
      color: #000;
      font-size: 4.67vmin !important;
    }

    &__phone {
      color: #000;
      font-size: 6.54vmin !important;
    }
  }
}
