.services-list-main {
    &__img {
        width: 100%;
        margin-bottom: 88px;
    }
    @media screen and (max-width:768px){
        &__img {
            margin-bottom: 11.46vw;
        }
    }
}