.program-list-info {
  padding-top: 58px;
  padding-bottom: 116px;

  &__text {
    max-width: 1567px;
    margin: 0 auto;
    padding: 0 24px;
    font-weight: 300;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 43px;
  }


  &__img {
    max-width: 706px;
    width: 100%;
    flex: 0 0 auto;
    object-fit: cover;
  }

  &__list {
    padding: 0px 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    max-width: 1567px;
    width: 100%;
    min-height: 383px;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    color: #000;

    &:not(:last-child) {
      margin-bottom: 47px;
    }
  }

  &__container {
    width: 100%;
    background-color: #fff;
    padding: 0 36px;
    padding-top: 54px;
    padding-bottom: 10px;
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;
  }

  &__description {
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 30px;
  }

  @media screen and (max-width: 1024px) {
    &__item {
      flex-direction: column;
      max-height: inherit;
    }

    &__img {
      max-width: inherit;
      max-height: 58.412vw;
      height: 100%;
    }

    &__title {
      font-size: 23px;
      margin-bottom: 13px;
    }

    &__description {
      font-weight: 200;
      font-size: 15px;
      line-height: 19px;
    }

    &__container {
      text-align: center;
      padding: 0 12px;
      padding-top: 31px;
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    &__text {
      font-size: 19px;
    }
  }
  @media screen and (max-width: 425px) {
    &__text {
      font-size: 5vw;
    }
  }
}
