.error-main {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 150px;
    margin-bottom: 60px;
    text-align: center;

    &__title {
        font-weight: 700;
        color: #FEED00;
        font-size: 120px;
        line-height: 125px;
        margin-bottom: 30px;
    }

    &__text {
        margin-bottom: 50px;
        font-weight: 400;
        font-size: 40px;
        line-height: 45px;
    }

    &__btn {
        max-width: 430px;
        width: 100%;
        margin: 0 auto;
        padding: 24px 50px;
        border-radius: 10px;
        font-size: 20px;
        line-height: 25px;
    }

    @media screen and (max-width:768px) {
        margin-top: 100px;
        &__title {
            font-size: 80px;
            line-height: 85px;
            margin-bottom: 30px;
        }
    
        &__text {
            font-size: 30px;
            line-height: 35px;
        }
    
        &__btn {
            max-width: 340px;
            padding: 24px 50px;
            font-size: 15px;
            line-height: 20px;
        }
    }
    @media screen and (max-width:428px) {
        margin-top: 80px;
        &__title {
            margin-bottom: 7.01vw;
            font-size: 18.69vw;
            line-height: 19.86vw;
        }
    
        &__text {
            margin-bottom: 11.68vw;
            font-size: 7.01vw;
            line-height: 8.18vw;
        }
    
        &__btn {
            max-width: 79.44vw;
            padding: 18px 20px;
            font-size: 3.50vw;
            line-height: 4.67vw;
        }
    }
}