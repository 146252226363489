.privilege {
  // padding-bottom: 237px;
  padding-bottom: 12.34vw;

  .swiper__privilege {
    display: none;
  }

  .swiper-pagination {
    &-bullet {
      opacity: 1;
      width: 13px;
      height: 12px;
      background: #999999;
      border: 1px solid #868686;
      &-active {
        background: #ffffff;
      }
    }
  }

  &__btn {
    display: none;
    margin: 0 auto;
    margin-top: 63px;
    text-align: center;
    max-width: 360px;
    width: 100%;
    text-transform: uppercase;
    background: #feed00;
    border-radius: 10px;
    padding: 20px 0px;
    font-weight: 700;
    font-size: 21px;
    line-height: 20px;
    color: #000000;
  }

  &__list {
    padding-left: 127px;
    &-desctop {
      // max-width: 1672px;
      max-width: 87.08vw;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // row-gap: 154px;
      // column-gap: 83px;
      row-gap: 8.02vw;
      column-gap: 2.32vw;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .swiper-slide:first-child {
      margin-bottom: 124px;
    }
    .swiper-slide {
      width: auto;
      display: flex;
    }
  }

  &__title {
    &-case {
      // margin-bottom: 139px;
      margin-bottom: 7.24vw;
      text-align: center;
    }
    &-text {
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-weight: 300;
      font-size: 40px;
      line-height: 38px;
      color: #ffffff;
      padding-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 0;
        width: calc(100% - 145px);
        height: 2px;
        background-color: #feed00;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    &-case {
      width: 100%;
    }
    &-svg {
      display: inline-block;
      // margin-right: 22px;
      margin-right: 1.15vw;
      opacity: 0.3;
      height: 4.17vw;
      width: 4.17vw;
      // width: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-title {
      // width: 386px;
      // width: 20.1vw;
  
      text-transform: uppercase;
      font-weight: 700;
      // font-size: 39px;
      // line-height: 41px;
      font-size: 1.7vw;
      line-height: 1.9vw;
      color: #ffffff;
    }

    &-text {
      width: 17.92vw;
      font-weight: 300;
      font-size: 0.89vw;
      line-height: 1.09vw;
      // width: 344px;
      // font-size: 17px;
      // line-height: 21px;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 63px;
    .swiper__privilege {
      display: flex;
    }

    &__title {
      &-text {
        font-size: 5.21vw;
        line-height: 5.47vw;
      }

      &-case {
        display: none;
      }
    }
    &__list {
      &-desctop {
        display: none;
      }
      justify-content: start;
      margin-bottom: 40px;
      align-items: inherit;
      padding-left: 0px;
      display: flex;
      flex-direction: row;
      .swiper-slide {
        margin-bottom: 0px;
        padding-left: 42px;
        display: flex;
        flex-direction: column;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }

    &__item {
      display: block;
      // width: 260px;
      &:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 36px;
      }
      &-title {
        width: auto;
        margin-bottom: 7px;
        font-size: 24px;
        line-height: 23px;
      }

      &-svg {
        display: inline-flex;
        align-items: center;
        margin-bottom: 14px;
        width: 85px;
        height: 85px;
      }

      &-text {
        width: auto;
        font-size: 15px;
        line-height: 18px;
      }
    }

    &__btn {
      display: block;
    }
  }
  @media screen and (max-width: 428px) {
    &__list {
      .swiper-slide {
        padding-left: 5.28vw;
        // &:first-child {
          
        //   // padding-left: 44px;
        // }
      }
    }

    &__btn {
      padding: 16px 0px;
      margin-top: 24px;
      width: 90vw;
    }

    &__item {
      &-title {
        font-size: 5.61vw;
        line-height: 5.37vw;
      }

      &-text {
        font-size: 3vw;
        line-height: 3.5vw;
      }
      &-svg {
        margin-bottom: 10px;
        width: 19.86vw;
        height: 19.86vw;
        svg {
          transform: scale(1);
        }
      }
    }
  }
}
